import React, {Component} from 'react'
import './Buildings.scss'

export class Buildings extends Component {
  render() {
    return (
      <svg role="presentation" viewBox="0 0 134.9 22.7" className="work__image--buildings">
        <path
          className="buildings__window--accent"
          d="M78,4.2H67.2V0.5H55.9v7.1H45.1V0.5H33.8v3.7H22.5V0.5H11.1v7.1h-11v15.2c25.7,0,51.9,0,77.9,0
					C78,16.6,78,10.4,78,4.2z"
        />
        <path
          className="buildings__window--accent"
          d="M113.3,7.9c-0.2,0-0.5,0-1,0c-3.2,0-6.7,0-9.7,0c0,4.9,0,9.7,0,14.8c3.6,0,7.2,0,10.7,0
					C113.3,17.9,113.3,12.9,113.3,7.9z"
        />
        <path
          className="buildings__window--accent"
          d="M95.2,4.2H84.9v18.6c3.4,0,6.9,0,10.3,0C95.2,16.8,95.2,10.3,95.2,4.2z"
        />
        <path
          className="buildings__window--main"
          d="M4.6,21v-0.3l2.5,2v0.1c2.9,0,5.9,0,8.8,0L13.8,21v-0.3l2.5,2v0.1c1.2,0,2.5,0,3.7,0L17.9,21v-0.3l2.5,2v0.1
					c3,0,6,0,9,0L27.3,21v-0.3l2.5,2v0.1c2.9,0,5.8,0,8.7,0L36.4,21v-0.3l2.5,2v0.1c1.3,0,2.6,0,3.8,0L40.6,21v-0.3l2.5,2v0.1
					c2.8,0,5.6,0,8.3,0L49.3,21v-0.3l2.5,2v0.1c2.9,0,5.9,0,8.8,0L58.5,21v-0.3l2.5,2v0.1c1.3,0,2.6,0,3.8,0L62.6,21v-0.3l2.5,2v0.1
					c3,0,6,0,9,0L72,21v-0.3l2.5,2v0.1c1.2,0,2.3,0,3.5,0c0-6.2,0-12.5,0-18.7H67.2V0.5H55.9v7H45.1v-7H33.8v3.6H22.5V0.5H11.1v7h-11
					v15.3c2.2,0,4.5,0,6.8,0L4.6,21z M77.3,11.7l-0.8-0.6V11l0.8,0.5V11.7z M76.4,6.2l0.8,0.6V7l-0.8-0.6V6.2z M76.1,6.3l1.2,0.9v0.2
					L76,6.5L76.1,6.3z M76,11l1.2,0.9v0.2L76,11.2V11z M77.1,16.3v0.2l-0.8-0.7v-0.1L77.1,16.3z M76,15.7l1.2,0.9v0.2L76,15.9V15.7z
					 M73.1,6.2l0.8,0.6V7l-0.8-0.6V6.2z M72.8,6.3L74,7.2v0.2l-1.2-0.9V6.3z M73.9,11.5v0.2L73.1,11v-0.1L73.9,11.5z M72.8,11l1.2,0.9
					v0.2l-1.2-0.9V11z M73.9,16.3v0.2l-0.8-0.6v-0.1L73.9,16.3z M72.8,15.7l1.2,0.9v0.2l-1.2-0.9V15.7z M72.7,20.6l1.8,1.4v0.3l-1.8-1.4
					V20.6z M69.8,6.2l0.8,0.6V7l-0.8-0.6V6.2z M69.4,6.3l1.2,0.9v0.2l-1.2-0.9V6.3z M70.5,11.5v0.2L69.7,11v-0.1L70.5,11.5z M69.4,11
					l1.2,0.9v0.2l-1.2-0.9V11z M70.4,16.3v0.2l-0.8-0.7v-0.1L70.4,16.3z M69.3,15.7l1.2,0.9v0.2l-1.2-0.9V15.7z M62.6,7.7L61.7,7V6.9
					l0.9,0.6V7.7z M65.9,16.6l-0.8-0.7v-0.1l0.8,0.6V16.6z M65.9,12.3l-0.8-0.7v-0.1l0.8,0.6V12.3z M65.9,7.7l-0.8-0.6V7l0.8,0.5V7.7z
					 M65,2.5l0.8,0.6v0.2L65,2.5L65,2.5z M64.7,2.6l1.2,0.9v0.2l-1.2-0.9V2.6z M64.7,7l1.2,0.9v0.2l-1.2-0.9V7z M64.7,11.5l1.2,0.9v0.2
					l-1.2-0.9V11.5z M64.7,15.8l1.2,0.9V17l-1.2-1V15.8z M63.3,20.6l1.8,1.4v0.3l-1.8-1.4V20.6z M61.8,2.5l0.8,0.6v0.2l-0.8-0.6V2.5z
					 M61.4,2.6l1.2,0.9v0.2l-1.2-0.9V2.6z M61.4,7l1.2,0.9v0.2l-1.2-0.9V7z M62.5,12v0.2l-0.8-0.7v-0.1L62.5,12z M61.4,11.5l1.2,0.9v0.2
					l-1.2-0.9V11.5z M62.5,16.4v0.2l-0.8-0.7v-0.1L62.5,16.4z M61.4,15.8l1.2,0.9v0.2L61.4,16V15.8z M60.9,22.2l-1.8-1.4v-0.3l1.8,1.4
					V22.2z M58.4,2.4L59.2,3v0.2l-0.8-0.6V2.4z M58.1,2.5l1.2,0.9v0.2l-1.2-0.9V2.5z M59.2,7.5v0.2L58.4,7V6.9L59.2,7.5z M58.1,7
					l1.2,0.9v0.2L58,7.2L58.1,7z M59.2,12v0.2l-0.8-0.6v-0.1L59.2,12z M58.1,11.5l1.2,0.9v0.2L58,11.7L58.1,11.5z M59.2,16.3v0.2
					l-0.8-0.6v-0.1L59.2,16.3z M58.1,15.8l1.2,0.9v0.2L58,16L58.1,15.8z M54.6,15.1l-0.8-0.6v-0.1l0.8,0.5V15.1z M53.7,9.6l0.8,0.6v0.2
					l-0.8-0.6V9.6z M53.4,9.7l1.2,0.9v0.2l-1.2-0.9V9.7z M53.3,14.4l1.2,0.9v0.2l-1.2-0.9V14.4z M50.4,9.6l0.8,0.6v0.2l-0.8-0.6V9.6z
					 M50.1,9.7l1.2,0.9v0.2L50,9.9L50.1,9.7z M51.2,15L51.2,15l-0.8-0.5v-0.1L51.2,15z M50,14.4l1.2,0.9v0.2L50,14.6V14.4z M50,20.6
					l1.8,1.4v0.3L50,20.9V20.6z M47.8,15.1L47,14.4v-0.1l0.8,0.6V15.1z M47.1,9.6l0.8,0.6v0.2l-0.8-0.6V9.6z M46.7,9.7l1.2,0.9v0.2
					l-1.2-0.9V9.7z M46.6,14.4l1.2,0.9v0.2l-1.2-0.9V14.4z M43.8,16.6L43,15.9v-0.1l0.8,0.6V16.6z M43.8,12.3L43,11.6v-0.1l0.8,0.6V12.3
					z M43.8,7.7L43,7.1V7l0.8,0.5V7.7z M43,2.5l0.8,0.6v0.2L43,2.7V2.5z M42.6,2.6l1.2,0.9v0.2l-1.2-0.9V2.6z M42.6,7l1.2,0.9v0.2
					l-1.2-0.9V7z M42.6,11.5l1.2,0.9v0.2l-1.2-0.9V11.5z M42.6,15.8l1.2,0.9V17l-1.2-1V15.8z M41.2,20.6L43,22v0.3l-1.8-1.4V20.6z
					 M40.5,7.7L39.6,7V6.9l0.9,0.6V7.7z M39.7,2.5l0.8,0.6v0.2l-0.8-0.6V2.5z M39.3,2.6l1.2,0.9v0.2l-1.2-0.9V2.6z M39.3,7l1.2,0.9v0.2
					l-1.2-0.9V7z M40.4,12v0.2l-0.8-0.7v-0.1L40.4,12z M39.3,11.5l1.2,0.9v0.2l-1.2-0.9V11.5z M40.4,16.4v0.2l-0.8-0.7v-0.1L40.4,16.4z
					 M39.3,15.8l1.2,0.9v0.2L39.3,16V15.8z M38.8,22.2L37,20.8v-0.3l1.8,1.4V22.2z M37.2,3v0.2l-0.8-0.6l-0.1-0.2L37.2,3z M36,2.5
					l1.2,0.9v0.2L36,2.7V2.5z M37.1,7.5v0.2L36.3,7V6.9L37.1,7.5z M36,7l1.2,0.9v0.2L36,7.2V7z M37.1,12v0.2l-0.8-0.6v-0.1L37.1,12z
					 M36,11.5l1.2,0.9v0.2L36,11.7V11.5z M37.1,16.3v0.2l-0.8-0.6v-0.1L37.1,16.3z M36,15.8l1.2,0.9v0.2L36,16V15.8z M32.6,11.7
					l-0.8-0.6V11l0.8,0.5V11.7z M31.7,6.2l0.8,0.6V7l-0.8-0.6V6.2z M31.4,6.3l1.2,0.9v0.2l-1.2-0.9V6.3z M31.3,11l1.2,0.9v0.2l-1.2-0.9
					V11z M32.4,16.3v0.2l-0.8-0.7v-0.1L32.4,16.3z M31.3,15.7l1.2,0.9v0.2l-1.2-0.9V15.7z M28.4,6.2l0.8,0.6V7l-0.8-0.6V6.2z M28.1,6.3
					l1.2,0.9v0.2L28,6.5L28.1,6.3z M29.2,11.5v0.2L28.4,11v-0.1L29.2,11.5z M28,11l1.2,0.9v0.2L28,11.2V11z M29.2,16.3v0.2l-0.8-0.6
					v-0.1L29.2,16.3z M28,15.7l1.2,0.9v0.2L28,15.9V15.7z M28,20.6l1.8,1.4v0.3L28,20.9V20.6z M25.8,11.7L25,11v-0.1l0.8,0.6V11.7z
					 M25.1,6.2l0.8,0.6V7l-0.8-0.6V6.2z M24.7,6.3l1.2,0.9v0.2l-1.2-0.9V6.3z M24.6,11l1.2,0.9v0.2l-1.2-0.9V11z M25.7,16.3v0.2
					l-0.8-0.7v-0.1L25.7,16.3z M24.6,15.7l1.2,0.9v0.2l-1.2-0.9V15.7z M17.8,16.9L16.7,16v-0.2l1.2,0.9L17.8,16.9z M17,15.9L17,15.9
					l0.8,0.5v0.2L17,15.9z M17.8,12.2L17,11.5v-0.1l0.8,0.6V12.2z M17.9,7.7L17,7V6.9l0.9,0.6V7.7z M21.2,16.6l-0.8-0.7v-0.1l0.8,0.6
					V16.6z M21.2,12.3l-0.8-0.7v-0.1l0.8,0.6V12.3z M21.2,7.7l-0.8-0.6V7l0.8,0.5V7.7z M20.3,2.5l0.8,0.6v0.2l-0.8-0.6V2.5z M20,2.6
					l1.2,0.9v0.2l-1.2-1V2.6z M20,7l1.2,0.9v0.2L20,7.2V7z M20,11.5l1.2,0.9v0.2L20,11.7V11.5z M20,15.8l1.2,0.9V17L20,16V15.8z
					 M18.6,20.6l1.8,1.4v0.3l-1.8-1.4V20.6z M17,2.5l0.8,0.6v0.2L17,2.5L17,2.5z M16.7,2.6l1.2,0.9v0.2l-1.2-0.9V2.6z M16.6,7l1.2,0.9
					v0.2l-1.2-0.9V7z M16.6,11.5l1.2,0.9v0.2l-1.2-0.9V11.5z M16.2,22.2l-1.8-1.4v-0.3l1.8,1.4V22.2z M14.5,16.5l-0.8-0.6v-0.1l0.8,0.5
					V16.5z M14.5,12.2l-0.8-0.6v-0.1l0.8,0.5V12.2z M14.5,7.7L13.7,7V6.9l0.8,0.6V7.7z M13.7,2.4L14.5,3v0.2l-0.8-0.6V2.4z M13.4,2.5
					l1.2,0.9v0.2l-1.2-0.9V2.5z M13.3,7l1.2,0.9v0.2l-1.2-0.9V7z M13.3,11.5l1.2,0.9v0.2l-1.2-0.9V11.5z M13.3,15.8l1.2,0.9v0.2L13.3,16
					V15.8z M9.9,15.1L9,14.5v-0.1l0.9,0.5V15.1z M9,9.6l0.8,0.6v0.2L9,9.7V9.6z M8.6,9.7l1.2,0.9v0.2L8.6,9.9V9.7z M8.6,14.4l1.2,0.9
					v0.2l-1.2-0.9V14.4z M5.7,9.6l0.8,0.6v0.2L5.7,9.8V9.6z M5.4,9.7l1.2,0.9v0.2L5.3,9.9L5.4,9.7z M6.5,15L6.5,15l-0.8-0.5v-0.1L6.5,15
					z M5.3,14.4l1.2,0.9v0.2l-1.2-0.9V14.4z M5.3,20.6L7,21.9v0.3l-1.8-1.4L5.3,20.6z M3.1,15.5L2,14.6v-0.2l1.2,0.9L3.1,15.5z
					 M2.3,14.4L2.3,14.4l0.8,0.5v0.2L2.3,14.4z M3.2,10.8L2,9.9V9.7l1.2,0.9V10.8z M3.2,10.4L2.3,9.7l0.1-0.1l0.8,0.6V10.4z"
        />
        <path
          className="buildings__window--main"
          d="M89.3,21v-0.3l2.5,2v0.1c1.1,0,2.3,0,3.4,0c0-6.1,0-12.5,0-18.7H84.9v18.7c2.2,0,4.3,0,6.5,0L89.3,21z
					 M94.4,16.5l-0.8-0.7v-0.1l0.8,0.6V16.5z M94.5,12.1l-1.2-0.9V11l1.2,0.9V12.1z M94.5,11.7l-0.8-0.6V11l0.8,0.5V11.7z M93.7,6.2
					l0.8,0.6V7l-0.8-0.6V6.2z M93.3,6.3l1.2,0.9v0.2l-1.2-0.9V6.3z M93.2,15.7l1.2,0.9v0.2l-1.2-0.9V15.7z M90.4,6.2l0.8,0.6V7l-0.8-0.6
					V6.2z M90,6.3l1.2,0.9v0.2L90,6.5V6.3z M91.2,11.6v0.1l-0.8-0.6V11L91.2,11.6z M90,11l1.2,0.9v0.2L90,11.2V11z M91.2,16.3v0.2
					l-0.8-0.6v-0.1L91.2,16.3z M90,15.7l1.2,0.9v0.2L90,15.9V15.7z M89.9,20.6l1.8,1.4v0.3l-1.8-1.4V20.6z M87.7,16.8l-1.2-0.9v-0.2
					l1.2,0.9V16.8z M87.7,16.5l-0.8-0.7v-0.1l0.8,0.6V16.5z M87.8,12.1l-1.2-0.9V11l1.2,0.9V12.1z M87.8,11.7L87,11v-0.1l0.8,0.6V11.7z
					 M87.9,7.4l-1.2-0.9V6.3l1.2,0.9V7.4z M87.9,7l-0.8-0.6V6.2l0.8,0.6V7z"
        />
        <path
          className="buildings__window--main"
          d="M106.8,20.9v-0.3l2.5,2v0.1c1.4,0,2.7,0,4.1,0c0-4.8,0-9.9,0-14.9c-0.3,0-0.5,0-1,0c-3.2,0-6.9,0-10,0
					c0,4.9,0,9.8,0,14.9c2.2,0,4.4,0,6.7,0L106.8,20.9z M112,15.1l-0.8-0.6v-0.1l0.8,0.5V15.1z M111.2,9.6l0.8,0.6v0.2l-0.8-0.6V9.6z
					 M110.8,9.7l1.2,0.9v0.2l-1.2-0.9V9.7z M110.8,14.4l1.2,0.9v0.2l-1.2-0.9V14.4z M107.9,9.6l0.8,0.6v0.2l-0.8-0.6V9.6z M107.5,9.7
					l1.2,0.9v0.2l-1.2-0.9V9.7z M107.9,14.5L107.9,14.5l0.8,0.5v0.1L107.9,14.5z M108.7,15.3v0.2l-1.2-0.9v-0.2L108.7,15.3z M107.4,20.5
					l1.8,1.4v0.3l-1.8-1.5V20.5z M105.3,15.4l-1.2-0.9v-0.2l1.2,0.9V15.4z M105.3,15.1l-0.8-0.7v-0.1l0.8,0.6V15.1z M105.3,10.8
					l-1.2-0.9V9.7l1.2,0.9V10.8z M105.4,10.4l-0.8-0.6V9.6l0.8,0.6V10.4z"
        />
        <path
          className="buildings__main"
          d="M67.3,3.6V0H55.8v7H45.2V0H33.7v3.6H22.6V0H11.1v7H0v15.7h4.6v-2.9h2.7v2.9h3.8h2.6v-2.9h2.8v2.9h1.3v-2.8h2.8
					v2.8h2h4.6v-2.9h2.7v2.9h3.8h2.6v-2.9h2.8v2.9h1.3v-2.8h2.8v2.8h2h4v-2.9H52v2.9h3.8h2.6v-2.9h2.8v2.9h1.3v-2.8h2.8v2.8h2h4.6v-2.9
					h2.7v2.9H78c0-6.4,0-12.7,0-19.1H67.3z M3.5,16.8H2v-3.1h1.5V16.8z M3.5,12.1H2V9h1.5V12.1z M6.7,16.8H5.2v-3.1h1.5V16.8z M6.7,12.1
					H5.2V9h1.5V12.1z M9.9,16.8H8.4v-3.1h1.5V16.8z M9.9,12.1H8.4V9h1.5V12.1z M14.7,18.3h-1.5v-3.1h1.5V18.3z M14.7,13.9h-1.5v-3.1h1.5
					V13.9z M14.7,9.4h-1.5V6.3h1.5V9.4z M14.7,5h-1.5V1.9h1.5V5z M18,18.3h-1.5v-3.1H18V18.3z M18,13.9h-1.5v-3.1H18V13.9z M18,9.4h-1.5
					V6.3H18V9.4z M18,5h-1.5V1.9H18V5z M21.3,18.3h-1.5v-3.1h1.5V18.3z M21.3,13.9h-1.5v-3.1h1.5V13.9z M21.3,9.4h-1.5V6.3h1.5V9.4z
					 M21.3,5h-1.5V1.9h1.5V5z M26.1,18.1h-1.5V15h1.5V18.1z M26.1,13.4h-1.5v-3.1h1.5V13.4z M26.1,8.7h-1.5V5.6h1.5V8.7z M29.3,18.1
					h-1.5V15h1.5V18.1z M29.3,13.4h-1.5v-3.1h1.5V13.4z M29.3,8.7h-1.5V5.6h1.5V8.7z M32.5,18.1H31V15h1.5V18.1z M32.5,13.4H31v-3.1h1.5
					V13.4z M32.5,8.7H31V5.6h1.5V8.7z M37.3,18.3h-1.5v-3.1h1.5V18.3z M37.3,13.9h-1.5v-3.1h1.5V13.9z M37.3,9.4h-1.5V6.3h1.5V9.4z
					 M37.3,5h-1.5V1.9h1.5V5z M40.6,18.3h-1.5v-3.1h1.5V18.3z M40.6,13.9h-1.5v-3.1h1.5V13.9z M40.6,9.4h-1.5V6.3h1.5V9.4z M40.6,5h-1.5
					V1.9h1.5V5z M44,18.3h-1.5v-3.1H44V18.3z M44,13.9h-1.5v-3.1H44V13.9z M44,9.4h-1.5V6.3H44V9.4z M44,5h-1.5V1.9H44V5z M48.2,16.8
					h-1.5v-3.1h1.5V16.8z M48.2,12.1h-1.5V9h1.5V12.1z M51.4,16.8h-1.5v-3.1h1.5V16.8z M51.4,12.1h-1.5V9h1.5V12.1z M54.6,16.8h-1.5
					v-3.1h1.5V16.8z M54.6,12.1h-1.5V9h1.5V12.1z M59.4,18.3h-1.5v-3.1h1.5V18.3z M59.4,13.9h-1.5v-3.1h1.5V13.9z M59.4,9.4h-1.5V6.3
					h1.5V9.4z M59.4,5h-1.5V1.9h1.5V5z M62.7,18.3h-1.5v-3.1h1.5V18.3z M62.7,13.9h-1.5v-3.1h1.5V13.9z M62.7,9.4h-1.5V6.3h1.5V9.4z
					 M62.7,5h-1.5V1.9h1.5V5z M66.1,18.3h-1.5v-3.1h1.5V18.3z M66.1,13.9h-1.5v-3.1h1.5V13.9z M66.1,9.4h-1.5V6.3h1.5V9.4z M66.1,5h-1.5
					V1.9h1.5V5z M70.8,18.1h-1.5V15h1.5V18.1z M70.8,13.4h-1.5v-3.1h1.5V13.4z M70.8,8.7h-1.5V5.6h1.5V8.7z M74,18.1h-1.5V15H74V18.1z
					 M74,13.4h-1.5v-3.1H74V13.4z M74,8.7h-1.5V5.6H74V8.7z M77.2,18.1h-1.5V15h1.5V18.1z M77.2,13.4h-1.5v-3.1h1.5V13.4z M75.7,8.7V5.6
					h1.5v3.1H75.7z"
        />
        <path
          className="buildings__main"
          d="M112.4,7.8c-3.2,0-6.9,0-10,0c0,4.9,0,9.8,0,14.9h4.3v-2.9h2.7v2.9h3.8h0.2c0-4.8,0-9.9,0-14.9
					C113.2,7.8,112.9,7.8,112.4,7.8z M105.7,16.8h-1.5v-3.1h1.5V16.8z M105.7,12.1h-1.5V9h1.5V12.1z M108.9,16.8h-1.5v-3.1h1.5V16.8z
					 M108.9,12.1h-1.5V9h1.5V12.1z M112.1,16.8h-1.5v-3.1h1.5V16.8z M112.1,12.1h-1.5V9h1.5V12.1z"
        />
        <path
          className="buildings__main"
          d="M84.9,3.6v19.1h4.2v-2.9h2.7v2.9h3.4c0-6.2,0-12.8,0-19.1H84.9z M88.1,18.1h-1.5V15h1.5V18.1z M88.1,13.4h-1.5
					v-3.1h1.5V13.4z M88.1,8.7h-1.5V5.6h1.5V8.7z M91.3,18.1h-1.5V15h1.5V18.1z M91.3,13.4h-1.5v-3.1h1.5V13.4z M91.3,8.7h-1.5V5.6h1.5
					V8.7z M94.5,18.1H93V15h1.5V18.1z M94.5,13.4H93v-3.1h1.5V13.4z M93,8.7V5.6h1.5v3.1H93z"
        />
        <path className="buildings__main" d="M108.9,4.2c0,0.1-0.1,1.2,0.7,2.1c0.6,0.6,1.6,0.9,2.5,0.6" />
        <line className="buildings__tower--detail" x1="109.8" y1="5.8" x2="107.9" y2="8.3" />
        <line className="buildings__tower--detail" x1="110.5" y1="6.3" x2="110.3" y2="8.2" />
        <line className="buildings__tower--detail" x1="109.4" y1="6.3" x2="110.2" y2="8" />
        <line className="buildings__tower--detail" x1="108.3" y1="7.8" x2="110.4" y2="6.8" />
        <line className="buildings__tower--detail" x1="112.1" y1="5.2" x2="111.7" y2="6.7" />
        <line className="buildings__tower--detail" x1="111.4" y1="4.6" x2="112.1" y2="5.3" />
        <line className="buildings__tower--detail" x1="111.1" y1="5.1" x2="111.6" y2="4.6" />
        <line className="buildings__tower--detail" x1="128.2" y1="1.6" x2="125.8" y2="23" />
        <line className="buildings__tower--detail" x1="129.3" y1="1.6" x2="130.4" y2="23" />
        <line className="buildings__tower--detail" x1="128.2" y1="1.6" x2="129.4" y2="3.3" />
        <line className="buildings__tower--detail" x1="127.9" y1="4.3" x2="129.4" y2="3.3" />
        <line className="buildings__tower--detail" x1="129.6" y1="5.9" x2="127.9" y2="4.3" />
        <line className="buildings__tower--detail" x1="127.6" y1="7.1" x2="129.6" y2="5.9" />
        <line className="buildings__tower--detail" x1="129.7" y1="8.9" x2="127.6" y2="7.1" />
        <line className="buildings__tower--detail" x1="127.2" y1="10.8" x2="129.7" y2="8.9" />
        <line className="buildings__tower--detail" x1="129.9" y1="12.6" x2="127.2" y2="10.8" />
        <line className="buildings__tower--detail" x1="126.8" y1="14.5" x2="129.9" y2="12.6" />
        <line className="buildings__tower--detail" x1="130.1" y1="16.8" x2="126.8" y2="14.5" />
        <line className="buildings__tower--detail" x1="126.3" y1="18.7" x2="130.1" y2="16.8" />
        <line className="buildings__tower--detail" x1="130.3" y1="21.2" x2="126.3" y2="18.7" />
        <line className="buildings__tower--detail" x1="125.9" y1="22.8" x2="130.3" y2="21.2" />
        <line className="buildings__tower--detail" x1="129.3" y1="1.6" x2="128.2" y2="1.6" />
        <path className="buildings__main" d="M130.5,0.9c-0.5,0.2-0.6,0.5-0.7,1c-0.1,0.5,0.2,1,0.7,1.3" />
        <path className="buildings__main" d="M126.9,0.9c0.5,0.2,0.8,0.6,0.8,1.1c0,0.5-0.3,0.9-0.8,1.1" />
        <line className="buildings__tower--detail" x1="127.1" y1="1.2" x2="126" y2="2" />
        <line className="buildings__tower--detail" x1="127.1" y1="2.7" x2="125.9" y2="2" />
        <line className="buildings__tower--detail" x1="127.2" y1="2" x2="125.8" y2="2" />
        <line className="buildings__tower--detail" x1="130.3" y1="1.2" x2="131.4" y2="2" />
        <line className="buildings__tower--detail" x1="130.2" y1="2.8" x2="131.4" y2="2.1" />
        <line className="buildings__tower--detail" x1="130.1" y1="2.1" x2="131.5" y2="2" />
        <line className="buildings__tower--detail" x1="127.5" y1="1.7" x2="128.2" y2="1.6" />
        <line className="buildings__tower--detail" x1="130" y1="1.7" x2="129.3" y2="1.6" />
        <line className="buildings__tower--detail" x1="127.5" y1="2.3" x2="128" y2="2.7" />
        <line className="buildings__tower--detail" x1="130" y1="2.3" x2="129.4" y2="2.7" />
        <line className="buildings__tower" x1="127.1" y1="5.5" x2="127.1" y2="3.7" />
        <line className="buildings__tower" x1="130.3" y1="5.5" x2="130.3" y2="3.7" />
        <line className="buildings__tower" x1="128.6" y1="5.5" x2="128.6" y2="3.7" />
        <line className="buildings__tower--detail" x1="127.3" y1="4.1" x2="130.2" y2="4.1" />
        <line className="buildings__tower--detail" x1="127.3" y1="5.1" x2="130" y2="5.1" />
        <line className="buildings__tower--detail" x1="127.2" y1="4.6" x2="130.1" y2="4.6" />
        <circle className="buildings__window--main" cx="125.7" cy="2" r="0.3" />
        <circle className="buildings__window--main" cx="131.6" cy="2" r="0.3" />
        <rect x="120.2" y="18.7" className="buildings__main" width="4.9" height="4.1" />
        <polygon className="buildings__main" points="122.6,17.3 125.8,19.1 119.5,19.1 " />
        <rect x="123.3" y="21" className="buildings__window--main" width="0.9" height="1.7" />
        <rect x="121.1" y="19.5" className="buildings__window--main" width="0.9" height="0.9" />
        <rect x="123.3" y="19.5" className="buildings__window--main" width="0.9" height="0.9" />
        <path className="buildings__st5" d="M121.8,20" />
        <path className="buildings__st5" d="M121.3,19.6" />
        <line className="buildings__st5" x1="123.5" y1="19.9" x2="123.8" y2="20.2" />
        <line className="buildings__st5" x1="123.5" y1="19.6" x2="124" y2="20.1" />
        <line className="buildings__st5" x1="123.5" y1="21.8" x2="124" y2="22.2" />
        <line className="buildings__st5" x1="123.5" y1="21.4" x2="124.1" y2="22" />
        <line className="buildings__st5" x1="121.3" y1="20" x2="121.6" y2="20.2" />
        <line className="buildings__st5" x1="121.3" y1="19.6" x2="121.8" y2="20.1" />
        <rect x="4.4" y="1.7" className="buildings__main" width="3" height="3.5" />
        <polygon className="buildings__main" points="5.9,0.3 4.3,1.7 7.5,1.7 " />
        <line className="buildings__tower--detail" x1="4.7" y1="5" x2="4.4" y2="7.2" />
        <line className="buildings__tower--detail" x1="7.1" y1="5.1" x2="7.4" y2="7.2" />
        <line className="buildings__tower--detail" x1="5.9" y1="5" x2="5.9" y2="7.3" />
        <rect x="121.1" y="21.2" className="buildings__window--main" width="0.9" height="0.9" />
        <line className="buildings__st5" x1="121.3" y1="21.7" x2="121.6" y2="21.9" />
        <line className="buildings__st5" x1="121.3" y1="21.3" x2="121.8" y2="21.8" />
      </svg>
    )
  }
}
