import React from 'react'
import './Tulips.scss'

const Tulips = ({className}) => (
  <svg role="presentation" viewBox="200 200 400 400" className={className}>
    <path
      className="tulip"
      d="M515.258,520.689c-0.65-11.385-1.458-21.27-2.413-29.371c-0.047-0.398,0.205-0.77,0.592-0.873
				c11.089-2.975,18.949-9.266,23.365-18.701c7.484-15.998,4.041-38.504-0.166-54.568c-3.283-12.532-7.556-23.243-9.713-28.303
				l-17,23.124c-0.362,0.493-0.92,0.809-1.526,0.868c-0.069,0.006-0.139,0.01-0.208,0.01c-0.541,0-1.06-0.202-1.458-0.569
				l-15.62-14.399l-7.124,18.441c-0.236,0.609-0.728,1.073-1.35,1.273c-0.213,0.068-0.435,0.103-0.657,0.103
				c-0.421,0-0.829-0.122-1.181-0.353l-25.707-16.875c0.542,8.295,2.214,26.243,7.115,44.125c3.811,13.906,8.792,24.953,14.804,32.832
				c7.296,9.562,16.16,14.41,26.345,14.41c0.969,0,2.009-0.049,3.089-0.146c0.025-0.002,0.049-0.002,0.072-0.002
				c0.197,0,0.389,0.072,0.537,0.203c0.166,0.148,0.264,0.357,0.27,0.58c0.186,6.799,0.217,15.93-0.042,26.43
				c-10.1-14.975-27.053-25.461-62.979-12.586c0.013,0.021,0.022,0.045,0.036,0.074c17.773,43.512,38.797,43.658,62.118,32.902
				c-1.132,20.115-3.358,42.521-7.36,62.484h18.091c0.002-23.914-0.389-45.73-1.135-64.537c19.531,3.842,35.374,0.957,39.672-34.395
				c0.002-0.021,0.004-0.039,0.01-0.059C530.385,500.852,520.024,509.629,515.258,520.689z"
    />
    <path
      className="tulip"
      d="M444.342,506.328l61.729,24.373c-0.992,22.785-3.329,48.867-7.798,71.102h19.719
				c0.003-27.48-0.509-50.758-1.355-69.729l39.065-29.264c-24.133-1.859-34.67,6.021-39.731,16.334
				c-0.663-11.227-1.455-20.543-2.325-27.922c11.324-3.035,19.363-9.471,23.887-19.137c7.588-16.217,4.125-38.922-0.117-55.114
				c-3.588-13.699-8.326-25.174-10.309-29.71l-17.834,24.257c-0.227,0.309-0.574,0.506-0.955,0.542
				c-0.043,0.004-0.086,0.006-0.129,0.006c-0.336,0-0.662-0.126-0.911-0.356l-16.492-15.203l-7.551,19.547
				c-0.146,0.379-0.457,0.672-0.844,0.796c-0.135,0.043-0.273,0.064-0.411,0.064c-0.259,0-0.517-0.075-0.738-0.221l-27.054-17.759
				c0.395,6.919,1.927,26.508,7.241,45.896c3.839,14.008,8.865,25.146,14.94,33.107c7.457,9.773,16.537,14.73,26.986,14.73
				c1.035,0,2.091-0.055,3.161-0.15c0.191,7.02,0.211,15.67-0.014,25.281C496.316,503.445,479.336,493.799,444.342,506.328z"
    />
    <path
      className="tulip"
      d="M383.06,471.068c-0.75,18.889-1.147,104.289-1.158,130.734h18.242c-3.944-23.516-6.153-108.48-7.288-128.639
				c23.812,10.982,45.277,10.832,63.424-33.592c0.014-0.029,0.022-0.055,0.036-0.076c-36.681-13.145-53.99-2.438-64.302,12.85
				c-0.265-10.721-0.233-20.042-0.044-26.983c0.007-0.228,0.106-0.442,0.276-0.593c0.151-0.134,0.346-0.208,0.547-0.208
				c0.025,0,0.05,0.001,0.074,0.003c1.104,0.1,2.165,0.15,3.154,0.15c10.398,0,19.448-4.951,26.898-14.715
				c6.138-8.044,11.223-19.322,15.114-33.521c5.005-18.257,6.712-36.583,7.265-45.052l-26.246,17.229
				c-0.36,0.236-0.776,0.361-1.207,0.361c-0.228,0-0.454-0.036-0.671-0.105c-0.636-0.204-1.138-0.678-1.378-1.3l-7.273-18.829
				l-15.949,14.701c-0.406,0.375-0.935,0.582-1.489,0.582c-0.071,0-0.141-0.003-0.211-0.01c-0.62-0.06-1.189-0.382-1.559-0.885
				l-17.357-23.61c-2.202,5.166-6.564,16.102-9.916,28.897c-4.296,16.401-7.812,39.38-0.17,55.714
				c4.508,9.633,12.534,16.058,23.854,19.094c0.396,0.106,0.652,0.486,0.605,0.892c-0.974,8.271-1.8,18.364-2.463,29.989
				c-4.867-11.293-15.445-20.256-41.326-18.253c0.005,0.019,0.008,0.037,0.01,0.061C346.943,472.047,363.118,474.992,383.06,471.068z"
    />
    <path
      className="tulip"
      d="M381.079,601.803h19.924c-4.427-26.793-6.755-114.525-7.752-137.436l63.027-24.885
				c-35.729-12.792-53.068-2.943-63.467,11.715c-0.229-9.811-0.21-18.644-0.015-25.812c1.094,0.099,2.172,0.154,3.229,0.154
				c10.668,0,19.939-5.06,27.553-15.039c6.203-8.129,11.334-19.502,15.254-33.803c5.425-19.796,6.989-39.797,7.394-46.861
				l-27.623,18.132c-0.227,0.148-0.489,0.225-0.754,0.225c-0.141,0-0.282-0.021-0.419-0.065c-0.395-0.127-0.711-0.426-0.861-0.812
				l-7.71-19.958l-16.838,15.521c-0.255,0.235-0.588,0.364-0.931,0.364c-0.044,0-0.087-0.002-0.132-0.007
				c-0.389-0.037-0.744-0.238-0.975-0.553l-18.209-24.767c-2.023,4.631-6.861,16.347-10.525,30.334
				c-4.331,16.533-7.866,39.716-0.119,56.271c4.619,9.871,12.827,16.44,24.388,19.541c-0.888,7.531-1.696,17.044-2.374,28.507
				c-5.168-10.529-15.927-18.576-40.566-16.677l39.886,29.878C381.61,484.875,381.09,571.002,381.079,601.803z"
    />
    <path
      className="tulip"
      d="M293.828,601.803h18.088c-4.002-20.182-6.226-54.695-7.358-74.812c23.321,10.756,44.344,10.609,62.119-32.9
				c0.013-0.031,0.021-0.053,0.035-0.076c-35.927-12.873-52.88-2.387-62.979,12.586c-0.248-9.994-0.23-18.742-0.068-25.424
				c9.705-2.975,22.075-11.773,26.771-21.893c4.173-8.99,6.656-20.85,7.38-35.25c0.932-18.519-1.304-36.405-2.562-44.622
				l-21.466,22.019c-0.294,0.302-0.666,0.509-1.077,0.6c-0.219,0.048-0.442,0.062-0.665,0.041c-0.651-0.061-1.231-0.408-1.592-0.952
				l-10.932-16.473l-12.15,17.427c-0.309,0.444-0.771,0.753-1.301,0.87c-0.067,0.015-0.136,0.026-0.204,0.035
				c-0.606,0.074-1.218-0.115-1.678-0.518l-21.584-18.917c-1.016,5.406-2.879,16.786-3.384,29.731
				c-0.646,16.593,0.842,39.313,11.599,53.321c5.493,7.152,17.219,12.875,27.118,14.418c-0.854,7.705-1.585,16.893-2.183,27.35
				c-4.767-11.061-15.128-19.84-40.476-17.877c0.005,0.018,0.007,0.035,0.01,0.059c4.297,35.35,20.139,38.234,39.671,34.393
				C294.216,543.746,293.827,577.807,293.828,601.803z"
    />
  </svg>
)

export default Tulips
