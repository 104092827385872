import React from 'react'
import './Jupiter.scss'

const Jupiter = ({changeTimeOfDay}) => (
  <svg
    role="presentation"
    tabIndex="0"
    viewBox="0 0 52.3 52.2"
    onClick={changeTimeOfDay}
    onKeyPress={changeTimeOfDay}
    className="hero__image--jupiter"
  >
    <circle className="jupiter--medium" cx="26.2" cy="26.1" r="26.1" />
    <path
      className="jupiter--light"
      d="M49,13.6C42.1,0.9,26.2-3.7,13.6,3.2C12.1,4,10.7,5,9.4,6.1c-5.9,8.1-7,19.2-1.8,28.6
				C14.7,47.6,31,52.3,44,45.2l0,0C52.5,37.2,54.9,24.2,49,13.6z M18.6,4.7c1.5,0,2.8,0.8,2.8,1.8s-1.3,1.8-2.8,1.8s-2.8-0.8-2.8-1.8
				S17,4.7,18.6,4.7z M11.8,35.6c-1,0-1.8-0.6-1.8-1.3s0.8-1.3,1.8-1.3s1.8,0.6,1.8,1.3S12.8,35.6,11.8,35.6z M18.1,40.6
				c-2,0-3.7-1.1-3.7-2.4c0-1.3,1.7-2.4,3.7-2.4s3.7,1.1,3.7,2.4S20.2,40.6,18.1,40.6z M26.9,40.9c-1.5,0-2.6-0.7-2.6-1.5
				s1.2-1.5,2.6-1.5c1.5,0,2.6,0.7,2.6,1.5S28.4,40.9,26.9,40.9z M27.9,8.5c-2.3,0-4.2-1.4-4.2-3.1s1.9-3.1,4.2-3.1s4.2,1.4,4.2,3.1
				C32.1,7.1,30.2,8.5,27.9,8.5z M36.3,9.5c-1.5,0-2.8-0.7-2.8-1.7c0-0.9,1.3-1.7,2.8-1.7s2.8,0.7,2.8,1.7S37.8,9.5,36.3,9.5z"
    />
    <path
      className="jupiter--dark"
      d="M52.2,28.6L52.2,28.6L52.2,28.6c0.1-2,0.1-3.9-0.2-5.8l0,0c0-0.1,0-0.1,0-0.2c0-0.2-0.1-0.4-0.1-0.7
				c0-0.2-0.1-0.3-0.1-0.5c0-0.1,0-0.1,0-0.2l0,0c-0.2-1.1-0.5-2.2-0.9-3.3c-1.6,0.1-3.2,0.2-3.5,0.2c-0.5,0.1-0.9,0.1-1.4,0.2
				c-0.9,0.1-1.8,0.3-2.6,0.3s-1.4-0.1-1.7-0.4c-0.2-0.2-0.5-0.6-0.7-1c-0.3-0.6-0.7-1.2-1.3-1.8c-2.1-2-5.4-3.3-8.9-3.3
				c-1.6,0-3.1,0.3-4.4,0.8c-1.3,0.5-3.2,1.6-4.4,3L21.9,16c-0.9,1.1-1.8,2.2-2.9,2.4c-0.3,0.1-0.6,0.1-1,0.1c-0.6,0-1.2-0.1-1.9-0.2
				c-0.7-0.1-1.5-0.2-2.3-0.2c-0.1,0-0.1,0-0.2,0c-1.8,0-3.8,0-6.3,0c-1.3,0-2.5,0-3.8,0c-0.7,0-1.3,0-2,0c-0.4,1.1-0.6,2.2-0.9,3.3
				l0,0c-0.1,0.3-0.1,0.7-0.2,1l0,0l0,0c-0.3,1.9-0.4,3.8-0.2,5.7l0,0c0,0.2,0,0.5,0.1,0.7v0.1l0,0c0.1,0.9,0.2,1.7,0.4,2.6
				c0.8,0,1.7,0,2.5,0c1.3,0,2.5,0,3.8,0c2.5,0,4.5,0,6.3,0c0.1,0,0.1,0,0.2,0c0.8,0,1.6-0.1,2.3-0.1c0.7-0.1,1.3-0.1,1.9-0.1
				c0.4,0,0.7,0,1,0.1c1.1,0.2,1.9,1,2.9,1.9l0.1,0.1c1.2,1.1,3.1,2,4.4,2.4c1.3,0.4,2.8,0.6,4.4,0.6c3.5,0,6.9-1,8.9-2.5
				c0.6-0.5,1-1,1.3-1.4c0.3-0.3,0.5-0.6,0.7-0.8c0.3-0.2,0.9-0.3,1.7-0.3s1.7,0.1,2.6,0.2c0.5,0.1,1,0.1,1.4,0.2
				c0.3,0,2.6,0.2,4.5,0.2c0.2-0.9,0.3-1.7,0.4-2.6l0,0v-0.1c0-0.2,0-0.4,0.1-0.5C52.2,28.9,52.2,28.8,52.2,28.6z"
    />
    <path
      className="jupiter--medium"
      d="M47.3,20.7c-0.5,0-0.9,0.1-1.4,0.2c-0.9,0.1-1.8,0.3-2.6,0.3s-1.4-0.1-1.7-0.4c-0.2-0.2-0.5-0.6-0.7-1
				c-0.3-0.6-0.7-1.2-1.3-1.8c-2.1-2-5.4-3.3-8.9-3.3c-1.6,0-3.1,0.3-4.4,0.8c-1.3,0.5-3.2,1.6-4.4,3l-0.1,0.1
				c-0.9,1.1-1.8,2.2-2.9,2.4c-0.3,0.1-0.6,0.1-1,0.1c-0.6,0-1.2-0.1-1.9-0.2c-0.7-0.1-1.5-0.2-2.3-0.2c-0.1,0-0.1,0-0.2,0
				c-1.8,0-3.8,0-6.3,0c-1.3,0-2.5,0-3.8,0c-0.9,0-1.9,0-2.8,0c-0.5,2.5-0.6,5.1-0.3,7.7c1,0,1.9,0,2.9,0c1.3,0,2.5,0,3.8,0
				c2.5,0,4.5,0,6.3,0c0.1,0,0.1,0,0.2,0c0.8,0,1.6-0.1,2.3-0.1c0.7-0.1,1.3-0.1,1.9-0.1c0.4,0,0.7,0,1,0.1c1.1,0.2,1.9,1,2.9,1.9
				l0.1,0.1c1.2,1.1,3.1,2,4.4,2.4c1.3,0.4,2.8,0.6,4.4,0.6c3.5,0,6.9-1,8.9-2.5c0.6-0.5,1-1,1.3-1.4c0.3-0.3,0.5-0.6,0.7-0.8
				c0.3-0.2,0.9-0.3,1.7-0.3s1.7,0.1,2.6,0.2c0.5,0.1,1,0.1,1.4,0.2c0.4,0,2.8,0.2,4.9,0.2h0.1c0.3-2.8,0.2-5.6-0.4-8.4
				C49.7,20.5,47.6,20.7,47.3,20.7z"
    />
    <path
      className="jupiter--light"
      d="M52,23.3c-1.8,0-4.1,0.2-4.5,0.2c-2.2,0.2-5.4,1.2-7.3-0.5c-0.9-0.8-1.3-2-2.2-2.9c-2.7-2.6-7.8-3.4-11.2-2
				c-1.2,0.5-2.7,1.4-3.6,2.5c-1.1,1.3-2.3,2.8-4,3.2c-1.9,0.5-3.9-0.2-5.8-0.1c-4.4,0.1-8.8,0-13.2,0c-0.1,1-0.2,1.9-0.2,2.9
				c3.6,0,7.2,0,10.8,0c2.1,0,4.3,0.1,6.4-0.1c1.8-0.1,3.6-0.1,4.9,1.4c4.1,5.1,12,5.9,16.5,1c1-1.1,1.6-2.3,3.2-2.4
				c0.7,0,7,0,10.5,0.1C52.2,25.5,52.2,24.4,52,23.3z M31.1,24.2c-3,0-5.4-1.1-5.4-2.4s2.4-2.4,5.4-2.4s5.4,1.1,5.4,2.4
				S34.1,24.2,31.1,24.2z"
    />
  </svg>
)

export default Jupiter
