import React from 'react'
import './Moon.scss'

const Moon = ({changeTimeOfDay}) => (
  <svg
    role="presentation"
    tabIndex="0"
    onClick={changeTimeOfDay}
    onKeyPress={changeTimeOfDay}
    className="hero__image--moon"
    viewBox="0 0 145 143"
  >
    <circle className="moon__st0" cx="71.9" cy="72.4" r="64.8" />
    <path
      className="moon__st1"
      d="M51.5,133.5c6.6,2.2,13.5,3.3,20.5,3.3c35.5,0,64.3-28.9,64.3-64.3c0-17.4-6.8-33.7-19.3-45.9
					c-6-1.8-12.2-2.7-18.4-2.7c-35.8,0-64.9,29.1-64.9,64.9C33.6,105.5,40,121.4,51.5,133.5z M98.4,103.1c-6.1,0-11.1,5-11.1,11.1
					c0,3.1,1.2,5.8,3.3,7.9c-4.5-1.4-7.8-5.6-7.8-10.6c0-6.1,5-11.1,11.1-11.1c3.1,0,5.9,1.2,7.9,3.3C100.7,103.3,99.6,103.1,98.4,103.1
					z M114.7,64.2c1.6,0,3,0.6,4,1.7c-0.5-0.2-1.1-0.3-1.7-0.3c-3.2,0-5.7,2.6-5.7,5.7c0,1.6,0.6,3,1.7,4.1c-2.3-0.7-4-2.9-4-5.5
					C109,66.8,111.5,64.2,114.7,64.2z M98.9,36.5c3.7,0,7,1.5,9.4,3.9c-1.3-0.4-2.6-0.6-4-0.6c-7.4,0-13.3,6-13.3,13.4
					c0,3.7,1.5,7,3.9,9.5c-5.4-1.7-9.3-6.8-9.3-12.8C85.6,42.5,91.6,36.5,98.9,36.5z M83.3,64.2c2.1,0,4.1,0.9,5.5,2.3
					c-0.7-0.2-1.5-0.4-2.3-0.4c-4.3,0-7.7,3.5-7.7,7.8c0,2.1,0.9,4.1,2.3,5.5c-3.1-1-5.4-3.9-5.4-7.4C75.5,67.7,79,64.2,83.3,64.2z
					 M72.6,116.1c1.3,0,2.5,0.5,3.4,1.4c-0.5-0.1-0.9-0.2-1.4-0.2c-2.6,0-4.8,2.1-4.8,4.8c0,1.3,0.5,2.5,1.4,3.4
					c-1.9-0.6-3.4-2.4-3.4-4.6C67.8,118.2,70,116.1,72.6,116.1z M62.6,106c-0.8-0.2-1.6-0.4-2.4-0.4c-4.5,0-8.2,3.7-8.2,8.2
					c0,2.3,0.9,4.3,2.4,5.8c-3.3-1-5.7-4.1-5.7-7.8c0-4.5,3.7-8.2,8.2-8.2C59.1,103.6,61.2,104.6,62.6,106z M49,66.4
					c1.7,0,3.2,0.7,4.3,1.8c-0.6-0.2-1.2-0.3-1.8-0.3c-3.3,0-6,2.7-6,6.1c0,1.7,0.7,3.2,1.8,4.3c-2.4-0.8-4.2-3.1-4.2-5.8
					C43,69.1,45.7,66.4,49,66.4z"
    />
    <path
      className="moon__st2"
      d="M51.2,133.8c6.5,2.2,13.5,3.4,20.7,3.4c35.8,0,64.8-29,64.8-64.8c0-18.2-7.5-34.6-19.5-46.3
					c-5.9-1.8-12.2-2.7-18.6-2.7c-36.1,0-65.4,29.3-65.4,65.4C33.2,106.2,40,122.1,51.2,133.8z M93.8,122.6c-1.3,0-2.6-0.2-3.7-0.7
					l-0.1-0.1c-4.2-1.6-7.2-5.6-7.2-10.4c0-6.1,5-11.1,11.1-11.1c3.1,0,5.9,1.2,7.9,3.3c-0.1,0-0.1,0-0.2-0.1c2.1,2,3.4,4.8,3.4,8
					C104.9,117.6,100,122.6,93.8,122.6z M120.4,69.8c0,3.2-2.6,5.7-5.7,5.7c-3.1,0-5.7-2.6-5.7-5.7c0-3.2,2.6-5.7,5.7-5.7
					C117.8,64.1,120.4,66.6,120.4,69.8z M98.9,36.5c7.4,0,13.3,6,13.3,13.3s-6,13.3-13.3,13.3c-7.4,0-13.3-6-13.3-13.3
					S91.5,36.5,98.9,36.5z M83.2,64.1c4.3,0,7.7,3.5,7.7,7.7c0,4.3-3.5,7.7-7.7,7.7c-4.3,0-7.7-3.5-7.7-7.7C75.5,67.5,79,64.1,83.2,64.1
					z M72.6,115.9c2.6,0,4.8,2.1,4.8,4.8c0,2.7-2.1,4.8-4.8,4.8s-4.8-2.1-4.8-4.8C67.8,118,69.9,115.9,72.6,115.9z M65,111.8
					c0,4.5-3.7,8.2-8.2,8.2s-8.2-3.7-8.2-8.2s3.7-8.2,8.2-8.2C61.4,103.6,65,107.3,65,111.8z M48.9,66.4c3.3,0,6,2.7,6,6s-2.7,6-6,6
					s-6-2.7-6-6S45.6,66.4,48.9,66.4z"
    />
  </svg>
)

export default Moon
