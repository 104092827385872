import React from 'react'
import './Vine1.scss'

const Vine1 = () => (
  <svg role="presentation" className="welcome__image--vine-1" viewBox="0 0 86.5 364.8">
    <path
      className="vine1"
      d="M85.4,109.3l-0.3-0.1c-13.9-3.3-23.9,5.8-29.9,15.4c0.4-4.2,0.7-8.5,0.8-12.9c5.8-2.1,11.4-9.6,13.5-19.2h-0.2
				c-5.1-0.8-10.1,0.7-13.4,3.7c-0.1-3.6-0.4-7.4-0.7-11.2c14-0.5,13-17.7,18.3-22.4h-0.2c-9.9-1.7-15.9,5.7-19.1,12.6
				c-0.5-3.6-1.1-7.3-1.8-11.1c17.2-2.3,12.5-23.2,18-29.8h-0.3c-11.9-0.6-17.8,8.4-20.4,17c-1.1-4.5-2.3-9.2-3.6-14
				c14-8.8-4-26-3.7-34.3l-0.2,0.1c-15.5,6.2-13.3,26.1-1.4,33.4c1.1,3.7,2,7.4,2.9,10.9c-4.9-7.3-12.6-13.6-23.8-12l-0.3,0.1
				c7.7,6.5,6.4,30.2,27.8,29.2c0.6,3.2,1.1,6.3,1.5,9.3c-3.5-5-10.7-7.4-17.5-5.5l-0.2,0.1c4.2,10.7,12.4,18.2,19.2,18.3
				c0.3,3.6,0.5,7,0.6,10.5c-3.6-5.1-11-7.5-17.9-5.6l-0.2,0.1c4,10.1,11.5,17.5,18.1,18.5c-0.2,5.3-0.5,10.5-1,15.7
				c-2.7-8.6-12.9-14.1-23.5-12.3h-0.3c3.5,14.8,12.8,26,21.9,27.2c-0.9,5.9-2,11.8-3.1,17.7c-2.6-10.7-9.5-23.2-24.6-22.5h-0.3
				c6.5,7.9,0.6,33,21.4,35.5l-0.1,0.4c0.3,0.1,0.6,0.2,1,0.2c-0.8,4.1-1.6,8.3-2.4,12.5c-2.2-7.5-11.1-12.2-20.1-10.7h-0.2
				c3,12.2,10.6,21.5,18.2,23c-0.8,4.6-1.5,9.4-2.2,14.3c-2.1-7.7-11.2-12.5-20.4-10.9h-0.2c3.1,12.8,11.3,22.4,19.1,23.4
				c-0.5,4.8-1,9.8-1.3,14.9c-5.1-10.8-14.8-22.3-30.3-19.6l-0.4,0.1c8.5,7.6,7.2,35.2,29.9,35.1c-0.1,4.8-0.2,9.7-0.2,14.9
				c-3.9-5.5-11.7-8.6-19.7-7.2h-0.3c3.4,13.6,12,23.9,20.4,24.9c0.1,3.2,0.3,6.4,0.5,9.7c-5.5-7-16.1-10.3-26.1-7.6l-0.3,0.1
				c6.2,15.6,18,26.8,28.1,27.7c0.3,3.3,0.7,6.7,1.1,10.1c-3.9-3.8-10.3-5.5-16.3-3.9l-0.2,0.1c4.1,10.4,11.8,17.9,18.5,18.7
				c0.5,3.8,1.1,7.7,1.8,11.6c-3-3.9-8.9-5.9-14.5-4.6h-0.2c3.5,10.3,12.4,18.8,20,16.6c13.4,0.7,14.8-15.9,20.6-20l-0.2-0.1
				c-9.3-2.6-16.2,3.9-20.2,10.3c-0.8-4.8-1.5-9.6-2.2-14.2c6.3-1.3,12.6-9.2,15.1-19.5h-0.2c-7.1-1.2-14.1,2.2-16.5,7.8
				c-0.6-4.5-1.1-8.9-1.5-13.2c24.9,0.9,24-29.2,33.5-37.2l-0.4-0.1c-17.9-3.5-28.9,11-34.3,23.2c-0.5-6.1-0.8-12-1-17.6
				c7.9-0.7,17-9.6,21.6-21.9l-0.5-0.3c-8.9-2.3-18.3,1.4-21.7,8.5c-0.1-5.4,0-10.6,0.1-15.6c10.1-1.4,20.8-13.4,25.6-29.5l-0.3-0.1
				c-9.4-1.9-18.9,1.4-24.1,7.7c0.3-3.6,0.6-7.1,1-10.5c6.3-1.2,13.2-8.2,16.8-17.9h-0.2c-5.4-1.4-11-0.1-14.7,3.1
				c0.6-4.3,1.3-8.5,2-12.6c14.8,1.9,16.2-16,22.3-20.3l-0.2-0.1c-9.1-2.5-16,3.5-20.2,9.7c0.8-4.2,1.6-8.3,2.4-12.3
				c18.1,0.8,20.4-21.9,28.5-27.5l-0.3-0.1c-10.4-3-18.8,2.3-24.6,9.1c0.8-4.3,1.5-8.5,2.2-12.8C75.5,143.4,76.4,116.3,85.4,109.3z"
    />
  </svg>
)

export default Vine1
