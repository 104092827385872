import React from 'react'
import './EarthMinimal.scss'

const EarthMinimal = ({changeTimeOfDay}) => (
  <svg
    role="presentation"
    tabIndex="0"
    viewBox="0 0 64.2 64.2"
    onClick={changeTimeOfDay}
    onKeyPress={changeTimeOfDay}
    className="hero__image--earth-minimal"
  >
    <path
      className="earth-minimal__land--accent"
      d="M31.6,63.2C14.1,63.2,0,49,0,31.6C0,14.1,14.2,0,31.6,0s31.6,14.2,31.6,31.6C63.3,49.1,49.1,63.2,31.6,63.2z"
    />
    <path
      className="earth-minimal__land--main"
      d="M32.1,0C14.4,0,0,14.4,0,32.1s14.4,32.1,32.1,32.1s32.1-14.4,32.1-32.1C64.1,14.3,49.8,0,32.1,0z M21.2,59.6
				c-7.9-3-13.6-9.3-16.3-16.8c3.6,6.5,9.7,12,17.4,15c6.6,2.5,13.4,3,19.7,1.6C35.5,62,28.2,62.3,21.2,59.6z M35.2,4.1
				c-6.7-1-13.1,0-18.7,2.4C22,2.9,28.8,1.2,35.8,2.2c8,1.2,14.6,5.7,18.8,11.9C49.9,8.9,43.1,5.2,35.2,4.1z"
    />
    <path
      className="earth-minimal__ocean--accent"
      d="M44.9,60.9c0.2-3.3-0.8-6.7-2.9-9.8c-3.4-5.1-9.2-5.3-15.4-5.6c-4.3-0.2-8.7-0.4-12.7-2.1
				c-1.3-0.6-2.1-1.6-2.9-2.6c-1-1.3-2-2.6-4.2-2.6c-1.2,0-2.7,0.4-4.6,1.4c-0.3,0.1-0.5,0.3-0.8,0.5c-0.7-2.6-1-5.2-1-8
				c0-8.6,3.6-17,9.8-22.9c8.3,3.1,16.4,7,19.9,14c0.4,0.8,0.7,1.8,1.1,2.8c0.6,1.6,1.2,3.3,2.2,4.6c0.8,1,1.7,1.6,2.7,1.6
				c1.3,0,2.4-0.9,3-2.4c0.3-0.6,0.4-1.2,0.6-1.7c0.6-1.7,1-2.9,2.9-3.6c1.5-0.5,3.1-1,4.6-1.4c2.5-0.6,5-1.3,7.3-2.5
				c1.8-0.9,1.8-2.5,1.8-4c0-0.3,0-0.6,0-1c0-0.2,0.1-0.7,0.2-1.1c0.2-0.6,0.3-1.1,0.4-1.6c4.2,5.5,6.6,12.3,6.6,19.3
				C63.7,44.5,56.4,55.8,44.9,60.9z"
    />
    <path
      className="earth-minimal__ocean--main"
      d="M56.6,11.5c0.5,0.6-0.5,3.2-0.5,4c-0.1,1.8,0.3,3.6-1.6,4.6c-3.7,2-8,2.4-11.9,3.9c-2.9,1-2.8,3.1-3.8,5.6
				c-1.1,2.5-3.3,3-5,0.8c-1.5-2-2.1-5.1-3.2-7.3c-3.8-7.6-12.8-11.5-20.4-14.3C3.9,14.6,0,23,0,32.2c0,3,0.4,5.9,1.2,8.7
				c0.4-0.3,0.8-0.6,1.2-0.8c8.1-3.9,7.1,2,11.3,3.9c10,4.4,22.4-0.8,28,7.6c2.3,3.5,3.1,7,2.7,10.3C56,57,64.1,45.6,64.1,32.3
				C64.1,24.2,61.3,17.1,56.6,11.5z M4.1,24c-1.3,4.7-1.4,9.4-0.4,13.5C2,33.4,1.7,28.4,3,23.4c1.5-5.6,5-10.1,9.2-12.8
				C8.6,13.8,5.6,18.5,4.1,24z M45.8,56c5.8-3.8,10.5-9.8,12.7-17.2c1.9-6.3,1.8-12.6,0.1-18.4c2.9,5.9,3.6,12.7,1.6,19.3
				C58,47.3,52.6,53,45.8,56z"
    />
  </svg>
)

export default EarthMinimal
