import React from 'react'
import './Superhero.scss'

export const Superhero = () => (
  <svg role="presentation" viewBox="0 0 121.6 75.2" className="contact__image--superhero">
    <path
      className="superhero__cape-shadow"
      d="M118.5,65c-0.2-0.3-0.5-0.5-0.7-0.8c-1-1.2-1.9-2.3-2.8-2.8c-1-0.9-1.9-1.4-2.6-1.4c-0.2,0-0.4,0-0.5,0.1
				c-0.3,0.2-0.4,0.6-0.6,1.3c0,0.2-0.1,0.3-0.1,0.5l0,0.2l-0.2,0c-0.4,0.1-0.8,0.1-1.3,0.1c-1.7,0-3.5-0.5-5.2-0.9
				c-1.6-0.4-3-0.8-4.2-0.8c-0.2,0-3,0.1-3,0.2c-0.2,0-0.4,0-0.5,0c-4-1.5-12.2-2.5-21-2.5c-1-0.7-2.5-1.4-5.1-1.4
				c-2.6,0-5.4,0.9-6.4,1.6c-1.7-0.5-4.1-1.2-6.8-2c35.1-0.5,67.3-6.2,62.2-31.6v-0.1c-0.9,1.1-1.9,2.2-2.9,3.3
				c-0.9-16.2-39,1.9-46.7-24.5c0.7-0.1,1.5-0.1,2.1,0c3.5,0.4,5.3,0.9,5.3,0.9c-9.2-10.9-22,1-24.2,6.4c-0.7-0.5-1.5-1-2.2-1.4
				c0,0,0,0,0,0c-4.3-2.6-9.4-4-14.9-4c-5.3,0-10.4,1.3-14.7,3.9c0,0,0,0,0,0c-1.7,0.9-3.3,2.1-4.8,3.3c-5.3,4.6-8.5,11.1-9.2,17.7
				c0,0,0.2,6.1,0.8,7.8c0.5,1.6,1.1,3.2,1.9,4.8c1.6,3.1,4,6,6.9,8.6c1.3,1.2,2.8,2.2,4.2,3.2c-0.9,0-2.6,0.1-3.2,0.1l0,0
				c0-0.4-0.4-0.6-1-0.8c-1.2,0.1-2.8,0.4-4.2,0.7c-1.1,0.3-2.2,0.5-3.2,0.6l-0.1,0l-0.1,0c-1.2-0.5-2.4-0.8-3.7-0.8
				c-2.4,0-4.5,1-5.3,2.4c-0.5,0.8-0.4,1.8,0.1,2.7c0.5,0.8,1.4,1.2,2.6,1.2c2.4,0,5.2-1.3,6-1.7l0,0l0,0c0.1,0,0.3,0,0.4,0
				c0.9,0,2.2,0.4,3.6,0.9c1.1,0.3,2.1,0.7,3.1,0.9c0.2,0,1.1-0.1,1.1-0.4l0,0c3,0.5,6.1,0.9,9.5,0.9c1.8,0,3.5-0.1,5.2-0.4
				c1.4,2.4,3.5,4.4,5.7,5.9c-0.1,0-0.2,0-0.3,0l0,0l0,0c-0.8-0.4-3.6-1.7-6-1.7c-1.3,0-2.2,0.4-2.6,1.2c-0.6,0.9-0.6,1.8-0.1,2.7
				c0.8,1.4,2.9,2.4,5.3,2.4l0,0c1.3,0,2.6-0.3,3.7-0.8l0.1,0l0.1,0c0.9,0.1,2,0.4,3.2,0.6c1.4,0.3,2.7,0.6,4,0.8
				c0.4-0.1,1.2-0.2,1.1-0.7l0,0c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0c0.1,0,0.2,0,0.2,0c0.1,0,0.1,0,0.2,0c0.1,0,0.2,0,0.2,0
				c0.1,0,0.1,0,0.2,0c0.1,0,0.2,0,0.2,0c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0
				c0,0,0.1,0,0.1,0c0.2,0,0.4,0,0.6,0l0,0c3.2,0,5.4-0.4,6.9-1.2l0,0c0.1,0,0.1-0.1,0.2-0.1c0,0,0,0,0,0c0.1,0,0.1-0.1,0.1-0.1
				c2.9,0,5.6-0.1,7.2-0.1c1.5,0.3,4.3,0.7,6.1,0.7l0,0c2,0,4.4-0.5,5.5-1.1c1.3,0.2,3.4,0.2,6,0.2c4,0,10.6-0.4,14.9-0.7
				c0,0.1,1.3,0.1,1.3,0c1.6,0.1,3.9,0.1,5.2,0.1c2.4,0,4.8-0.2,7.2-0.5c0.7-0.1,1.3-0.1,1.9-0.1c3.1,0,5,1.1,6.4,1.9
				c0.7,0.4,1.3,0.8,1.8,0.8l0,0c0.2,0,0.3,0,0.5-0.1c0.2-0.1,0.4-0.3,0.4-0.6C121.6,69.3,120.5,67.3,118.5,65z"
    />
    <path
      className="superhero__cape-main"
      d="M118.5,65c-0.2-0.3-0.5-0.5-0.7-0.8c-1-1.2-1.9-2.3-2.8-2.8c-1-0.9-1.9-1.4-2.6-1.4c-0.2,0-0.4,0-0.5,0.1
				c-0.3,0.2-0.4,0.6-0.6,1.3c0,0.2-0.1,0.3-0.1,0.5l0,0.2l-0.2,0c-0.4,0.1-0.8,0.1-1.3,0.1c-1.7,0-3.5-0.5-5.2-0.9
				c-1.6-0.4-3-0.8-4.2-0.8c-0.2,0-3,0.1-3,0.2c-0.2,0-0.4,0-0.5,0c-4-1.5-12.2-2.5-21-2.5c-1-0.7-2.5-1.4-5.2-1.4
				c-2.6,0-5.4,0.9-6.4,1.6c-2.6-0.8-7.2-2.1-11.5-3.3c0-0.1-0.2-0.5-0.3-0.9c0,0,0.1,0,0.1,0l0.1-0.2c0.3-0.2,0.6-0.4,0.9-0.6
				c32.7-0.2,50.9-11.7,63.3-25.4c-0.9-16.2-39,1.9-46.7-24.5c-5.3,0.6-12.9,5-13.6,9.9c-0.3-0.3-0.6-0.5-0.9-0.8
				c-1.4-1.2-3-2.3-4.6-3.2c0,0,0,0,0,0c-4.3-2.6-9.4-4-14.9-4c-5.3,0-10.4,1.3-14.7,3.9c0,0,0,0,0,0c-1.7,0.9-3.3,2.1-4.8,3.3
				c-5.3,4.6-8.6,10.9-9.2,17.6c0,0,0,6,0.8,8c0.5,1.6,1.1,3.2,1.9,4.8c1.6,3.1,4,6,6.9,8.6c1.3,1.2,2.8,2.2,4.2,3.2
				c-0.9,0-2.6,0.1-3.2,0.1l0,0c0-0.4-0.4-0.6-1-0.8c-1.2,0.1-2.8,0.4-4.2,0.7c-1.1,0.3-2.2,0.5-3.1,0.6l-0.1,0l-0.1,0
				c-1.2-0.5-2.4-0.8-3.7-0.8c-2.4,0-4.5,1-5.3,2.4c-0.5,0.8-0.4,1.8,0.1,2.7c0.5,0.8,1.4,1.2,2.6,1.2c2.4,0,5.2-1.3,6-1.7l0,0l0,0
				c0.1,0,0.3,0,0.4,0c0.9,0,2.2,0.4,3.6,0.9c1.1,0.3,2.1,0.7,3.1,0.9c0.2,0,1.1-0.1,1.1-0.4l0,0c3,0.5,6.1,0.9,9.5,0.9
				c1.8,0,3.5-0.1,5.2-0.4c1.4,2.4,3.5,4.4,5.7,5.9c-0.1,0-0.2,0-0.3,0l0,0l0,0c-0.8-0.4-3.6-1.7-6-1.7c-1.3,0-2.2,0.4-2.6,1.2
				c-0.6,0.9-0.6,1.8-0.1,2.7c0.8,1.4,2.9,2.4,5.3,2.4l0,0c1.3,0,2.6-0.3,3.7-0.8l0.1,0l0.1,0c0.9,0.1,2,0.4,3.2,0.6
				c1.4,0.3,2.7,0.6,4,0.8c0.4-0.1,1.2-0.2,1.1-0.7l0,0c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0c0.1,0,0.2,0,0.2,0c0.1,0,0.1,0,0.2,0
				c0.1,0,0.2,0,0.2,0c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0
				c0.1,0,0.1,0,0.2,0c0,0,0.1,0,0.1,0c0.2,0,0.4,0,0.6,0l0,0c3.2,0,5.4-0.4,6.9-1.2l0,0c0.1,0,0.1-0.1,0.2-0.1c0,0,0,0,0,0
				c0.1,0,0.1-0.1,0.1-0.1c2.9,0,5.6-0.1,7.2-0.1c1.5,0.3,4.3,0.7,6.1,0.7l0,0c2,0,4.4-0.5,5.5-1.1c1.3,0.2,3.4,0.2,6,0.2
				c4,0,10.6-0.4,14.9-0.7c0,0.1,1.3,0.1,1.3,0c1.6,0.1,3.9,0.1,5.2,0.1c2.4,0,4.8-0.2,7.2-0.5c0.7-0.1,1.3-0.1,1.9-0.1
				c3.1,0,5,1.1,6.4,1.9c0.7,0.4,1.3,0.8,1.8,0.8l0,0c0.2,0,0.3,0,0.5-0.1c0.2-0.1,0.4-0.3,0.4-0.6C121.6,69.3,120.5,67.3,118.5,65z"
    />
    <path
      className="superhero__clothing--accent"
      d="M118.5,65c-0.2-0.3-0.5-0.5-0.7-0.8c-1-1.2-1.9-2.3-2.8-2.8c-1-0.9-1.9-1.4-2.6-1.4c-0.2,0-0.4,0-0.5,0.1
				c-0.3,0.2-0.4,0.6-0.6,1.3c0,0.2-0.1,0.3-0.1,0.5l0,0.2l-0.2,0c-0.4,0.1-0.8,0.1-1.3,0.1c-1.7,0-3.5-0.5-5.2-0.9
				c-1.6-0.4-3-0.8-4.2-0.8c-0.8,0-1.4,0.2-1.8,0.5c0-0.2-1.2-0.4-1.2-0.4c-4.2-1.7-13.1-2.7-22-2.7c-1-0.6-2.5-1.1-4.7-1.1
				c-2.7,0-5,0.7-5.9,1.4c0,0.1-7-1.8-11.5-3c0-0.1-0.5-0.2-1.2-0.4c-4.8,3.1-10.3,4.9-15.8,5c-5,0-10.7-1.9-15.6-5.2
				c-0.8,0-1.6,0-2.3-0.1c-0.2-0.2-0.7-0.4-1.1-0.5c-1.2,0.1-2.6,0.4-4,0.8c-1.1,0.3-2.2,0.5-3.2,0.6l-0.1,0l-0.1,0
				c-1.2-0.5-2.4-0.8-3.7-0.8c-2.4,0-4.5,1-5.3,2.4c-0.5,0.8-0.4,1.8,0.1,2.7c0.5,0.8,1.4,1.2,2.6,1.2c2.4,0,5.2-1.3,6-1.7l0,0l0,0
				c0.1,0,0.3,0,0.4,0c0.9,0,2.2,0.4,3.6,0.9c0.2,0.1,0.4,0.1,0.6,0.2c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0.8,0.3,1.7,0.5,2.4,0.7
				c0.4,0,0.9-0.1,1.1-0.1c4.3,0.7,9.5,1.4,14.9,0.5c0.7,1.2,1.6,2.2,2.4,3.1c1,1,2,1.8,3,2.6c-0.1,0-0.2,0-0.2,0l0,0l0,0
				c-0.8-0.4-3.6-1.7-6-1.7c-1.3,0-2.2,0.4-2.6,1.2c-0.6,0.9-0.6,1.8-0.1,2.7c0.8,1.4,2.9,2.4,5.3,2.4l0,0c1.3,0,2.6-0.3,3.7-0.8l0.1,0
				l0.1,0c0.6,0.1,1.3,0.2,2,0.4c0,0,0.4,0.1,0.7,0.1c0.2,0,0.3,0.1,0.5,0.1c1.4,0.3,2.7,0.6,4,0.7c0.4-0.1,1-0.3,1.2-0.4
				c3.6,0.2,7.7,0.2,10.2-1.1c0.2-0.1,0.4-0.2,0.5-0.3c0,0,7.6-0.2,7.6-0.2c1.4,0.3,3.4,0.5,5.5,0.5l0,0c2.1,0,3.8-0.3,4.8-0.9
				c0,0,0.5-0.2,0.5-0.2c1.3,0.2,3.5,0.3,6.2,0.3l0,0c4.8,0,11.6-0.3,14.9-0.9c1.2,0.2,4.8,0.3,6.5,0.3c2.4,0,4.8-0.2,7.2-0.5
				c0.7-0.1,1.3-0.1,1.9-0.1c3.1,0,5,1.1,6.4,1.9c0,0,0.1,0,0.1,0c0.6,0.4,1.1,0.7,1.6,0.7l0,0c0,0,0.1,0,0.1,0c0,0,0,0,0.1,0l0,0
				c0.2,0,0.3,0,0.5-0.1c0.2-0.1,0.4-0.3,0.4-0.6C121.6,69.3,120.5,67.3,118.5,65z"
    />
    <path
      className="superhero__boot-shadow"
      d="M117.8,63.9c-2.3-3-4.7-5-6.1-4.3c-0.6,0.3-0.6,1.1-0.8,2.1c-3.9,0.7-9.3-2.5-12.1-1.4v0
				c-0.1,0.9-0.5,6.9-0.6,8.4c3.4,0.3,7.4,0.4,12.1-0.3c6.9-1,8.9,3.7,10.8,2.5C122.5,70,120.1,66.9,117.8,63.9z"
    />
    <path
      className="superhero__glove"
      d="M18.3,53.8c-2.5-0.1-6,1.1-8.5,1.4C4.4,52.9-1.8,56.1,0.5,60c1.6,2.6,6.7,0.8,9-0.4c1.6-0.3,5.5,1.7,8.1,1.9
				C17.7,61.3,18.3,53.9,18.3,53.8z"
    />
    <path
      className="superhero__accessory--accent"
      d="M18,53.6c0,0.2-0.4,7.4-0.5,8.4c0.6,0.1,1.2,0.1,1.7,0.1c0-0.1,0.6-8.2,0.6-8.2C19.3,53.7,18.7,53.7,18,53.6z"
    />
    <path
      className="superhero__accessory--accent"
      d="M96.2,69.2c0-0.2,0.7-7.9,0.8-8.9c0.6,0,1.2,0,1.8,0.1c0,0.1-0.7,8.7-0.7,8.8C97.4,69.2,96.8,69.2,96.2,69.2z"
    />
    <path
      className="superhero__boot"
      d="M118.6,64.6c-2.3-2.7-3.8-5-6-4c-0.6,0.3-0.6,1-0.8,1.9c-4,0.7-10.2-2.2-13.1-1c-0.2,2.2-0.5,6.5-0.6,7.4
				c3.5,0.3,7.4,0.4,12.2-0.3c7-0.9,8.8,3.4,10.7,2.4C122.5,70.1,120.9,67.3,118.6,64.6z"
    />
    <path
      className="superhero__clothing--main"
      d="M76.6,59.3C76.6,59.2,76.7,59.2,76.6,59.3c-0.6-1-2.3-2.4-6.1-2.4c-2.6,0-5,0.7-5.9,1.4
				c-2.5-0.8-7.8-2.3-12.6-3.6c-4.8,3.2-10.5,5.1-16,5.1c-1.5,0-3.2-0.2-4.8-0.5c1.9,5.2,8.3,9.4,11.4,10.3l0.3,0
				c0.1,0.7,22.6,0.3,22.6,0.3c1.3,0.2,3,0.4,4.6,0.4l0,0c2,0,4-0.4,5.1-0.9l0,0.1c4,0.6,16.1,0.2,20.9-0.6c0.1-1.1,0.5-5.1,0.6-7.3
				C92.9,59.9,85.4,59.2,76.6,59.3z"
    />
    <path
      className="superhero__clothing--accent"
      d="M52.4,54.4c-2,1.4-4.2,2.6-6.5,3.5c0.8,4.5,3.3,5.8,4.9,7.2c-1.7,0-3.5,0.3-5.2,0.6c-0.3-0.3-2.6-0.1-4,0.4
				c-0.6,0.2-1.3,0.4-1.9,0.6c0,0-0.1,0-0.1,0c0,0,0,0,0,0c-0.6,0.2-1.2,0.3-1.6,0.3c-0.1,0-0.3,0-0.4,0l0,0l0,0
				c-0.8-0.4-3.6-1.7-6-1.7c-1.3,0-2.2,0.4-2.6,1.2c-0.6,0.9-0.6,1.8-0.1,2.7c0.8,1.4,2.9,2.4,5.3,2.4l0,0c1.3,0,2.6-0.3,3.7-0.8l0.1,0
				l0.1,0c0.6,0.1,1.3,0.2,2,0.4c0,0,0.4,0.1,0.7,0.1c0.2,0,0.3,0.1,0.5,0.1c1.7,0.4,5,0.5,5,0.3c3.6,0.2,7.8,0.2,10.4-1.1
				C60.4,68.5,54.2,57.8,52.4,54.4z"
    />
    <path
      className="superhero__glove"
      d="M46.5,72.3c-2.5,0.1-6-1.1-8.5-1.4c-5.4,2.4-11.6-0.9-9.3-4.7c1.6-2.6,6.7-0.8,9,0.4c1.6,0.3,5.5-1.7,8.1-1.9
				C45.8,64.8,46.5,72.2,46.5,72.3z"
    />
    <path
      className="superhero__accessory--accent"
      d="M46.1,72.8c0-0.2-0.4-7.4-0.4-8.4c0.6-0.1,1.2-0.1,1.7-0.1c0,0.1,0.5,8.2,0.5,8.3
				C47.3,72.7,46.7,72.7,46.1,72.8z"
    />
    <path
      className="superhero__accessory--accent"
      d="M33,59.6c0.9,0.9,1.8,2,2.5,2.9l0,0c0.3-0.6,0.7-2.1,1.1-3.4C35.4,59.4,34.2,59.5,33,59.6z"
    />
    <path
      className="superhero__accessory--accent"
      d="M63.9,70c2.5,0.6,16.2,6.7,18.7,4.8c0,0-2.2-10.1,3.6-16.9c-3.9-3.8-20.5-1.4-22.2,0.3v0.1
				C66.1,65.2,64.6,69.3,63.9,70z"
    />
    <path
      className="superhero__nose"
      d="M35.8,60C25,60,10.8,50.6,7.9,37.9l-0.1-0.3l-0.3,0c-0.1,0-0.2,0-0.3,0c-1.8,0-3.3-1.5-3.3-3.3
				c0-1.8,1.4-3.3,3.2-3.3l0.3,0l0-0.3c0.7-6.6,3.9-12.7,9.2-17.3c5.3-4.5,12.1-7,19.3-7c7.2,0,14.1,2.5,19.3,7.1
				c5.2,4.5,8.5,10.7,9.1,17.3l0,0.3l0.3,0c1.7,0.1,3.1,1.5,3.1,3.3c0,1.8-1.5,3.3-3.3,3.3c-0.1,0-0.1,0-0.2,0l-0.4,0l-0.1,0.3
				C61.5,50,48.9,59.9,36,60L35.8,60z"
    />
    <path
      className="superhero__skin"
      d="M64.9,30.3C63.6,16.4,51.2,5.6,36.1,5.6C21,5.6,8.6,16.4,7.3,30.2c-2,0-3.6,1.6-3.6,3.7c0,2,1.6,3.7,3.6,3.7
				c0.1,0,0.3,0,0.4,0c3,12.8,17.3,22.6,28.4,22.5c12.6-0.1,25.7-9.7,28.4-22.4c0.1,0,0.2,0,0.2,0c2,0,3.6-1.6,3.6-3.7
				C68.4,31.9,66.9,30.3,64.9,30.3z M5,34.1c-0.1-1,0.4-1.9,1.2-2.4c-0.5,0.6-0.8,1.4-0.7,2.3c0.1,0.9,0.4,1.7,1,2.1
				C5.7,35.9,5.1,35.1,5,34.1z M35.8,43.2c-1.4,0-2.7,0.6-3.5,1.4c0.6-1.2,1.9-2.1,3.5-2.1c1.5,0,2.8,0.8,3.4,2.1
				C38.5,43.7,37.2,43.2,35.8,43.2z M67.1,34c-0.1,1-0.7,1.8-1.4,2.1c0.5-0.5,0.9-1.3,1-2.2c0.1-0.9-0.2-1.8-0.7-2.3
				C66.7,32.1,67.2,33,67.1,34z"
    />
    <path
      className="superhero__mouth"
      d="M36,51.4c-1.9-0.2-3.4-0.9-4.2-1.8c-0.1,0.2-0.1,0.4-0.1,0.6c-0.2,2.1,1.6,4.1,4,4.3c2.4,0.2,4.5-1.3,4.7-3.5
				c0-0.2,0-0.4,0-0.6C39.5,51.1,37.8,51.5,36,51.4z"
    />
    <g>
      <path
        className="superhero__tongue"
        d="M38,54c0.1-0.8-0.4-1.4-1.1-1.5c-0.5,0-0.9,0.2-1.1,0.6c-0.2-0.4-0.5-0.8-1-0.8c-0.7-0.1-1.3,0.5-1.3,1.3
					c0,0,0,0.1,0,0.1c0.6,0.5,1.4,0.7,2.2,0.8C36.5,54.6,37.3,54.5,38,54C38,54.1,38,54,38,54z"
      />
    </g>
    <circle className="superhero__cheeks" cx="22.6" cy="44.8" r="3.1" />
    <circle className="superhero__cheeks" cx="48.7" cy="44.7" r="3.1" />
    <path
      className="superhero__mask"
      d="M35.8,37.4c0-4.2,4.1-7.6,9.3-7.6c6.2,0,10.5,3.5,14.3,3.5c5.6,0,5.5,0,5.5,0v2.2c0,3.2-18.3,13.6-29.1,5.3
				C25,49.1,7,38.9,7,35.6v-2.2c0,0-0.4-0.1,5.2-0.1c3.8,0,8.1-3.5,14.4-3.5C31.6,29.8,35.8,33.2,35.8,37.4z"
    />
    <circle className="superhero__eye-shadow" cx="25.3" cy="36.9" r="5.4" />
    <path
      className="superhero__eye--white"
      d="M28.2,32.4c-0.6-0.2-1.1-0.3-1.8-0.3c-3,0-5.4,2.4-5.4,5.4c0,1.8,0.8,3.3,2.1,4.3c0.6,0.3,1.4,0.4,2.1,0.4
				c3,0,5.4-2.4,5.4-5.4C30.7,35,29.7,33.4,28.2,32.4z"
    />
    <path
      className="superhero__iris"
      d="M26,33.1c-0.8,0-1.6,0.2-2.3,0.6c0.4,0.2,0.8,0.6,0.8,1.2c0,0.7-0.6,1.3-1.3,1.3c-0.5,0-0.9-0.3-1.1-0.7
				c-0.3,0.6-0.5,1.3-0.5,2c0,2.4,2,4.4,4.4,4.4s4.4-2,4.4-4.4C30.4,35,28.4,33.1,26,33.1z M26.1,36.5c-1.3,0-1.3-2,0-2
				C27.5,34.4,27.5,36.5,26.1,36.5z"
    />
    <path
      d="M26,33.6c-0.7,0-1.3,0.2-1.8,0.5c0.2,0.2,0.3,0.5,0.3,0.8c0,0.7-0.6,1.3-1.3,1.3c-0.3,0-0.5-0.1-0.7-0.2
				c-0.2,0.5-0.3,1-0.3,1.5c0,2.1,1.7,3.8,3.8,3.8s3.8-1.7,3.8-3.8C29.8,35.4,28.1,33.6,26,33.6z M26.2,36.4c-0.5,0-0.9-0.4-0.9-0.9
				s0.4-0.9,0.9-0.9s0.9,0.4,0.9,0.9C27.1,35.9,26.7,36.4,26.2,36.4z"
    />
    <circle className="superhero__eye-shadow" cx="45.6" cy="37.2" r="5.4" />
    <path
      className="superhero__eye--white"
      d="M48.5,32.7c-0.6-0.2-1.1-0.3-1.8-0.3c-3,0-5.4,2.4-5.4,5.4c0,1.8,0.8,3.3,2.1,4.3c0.6,0.3,1.4,0.4,2.1,0.4
				c3,0,5.4-2.4,5.4-5.4C51,35.3,50,33.7,48.5,32.7z"
    />
    <path
      className="superhero__iris"
      d="M46.2,33.4c-0.8,0-1.6,0.2-2.3,0.6c0.4,0.2,0.8,0.6,0.8,1.2c0,0.7-0.6,1.3-1.3,1.3c-0.5,0-0.9-0.3-1.1-0.7
				c-0.3,0.6-0.5,1.3-0.5,2c0,2.4,2,4.4,4.4,4.4s4.4-2,4.4-4.4S48.7,33.4,46.2,33.4z M46.4,36.8c-1.3,0-1.3-2,0-2S47.7,36.8,46.4,36.8z
				"
    />
    <path
      d="M46.2,34c-0.7,0-1.3,0.2-1.8,0.5c0.2,0.2,0.3,0.5,0.3,0.8c0,0.7-0.6,1.3-1.3,1.3c-0.3,0-0.5-0.1-0.7-0.2
				c-0.2,0.5-0.3,1-0.3,1.5c0,2.1,1.7,3.8,3.8,3.8s3.8-1.7,3.8-3.8C50.1,35.7,48.4,34,46.2,34z M46.4,36.7c-0.5,0-0.9-0.4-0.9-0.9
				s0.4-0.9,0.9-0.9s0.9,0.4,0.9,0.9C47.3,36.3,46.9,36.7,46.4,36.7z"
    />
    <path
      className="superhero__hair--accent"
      d="M85.6,31.6c-0.6-0.5-0.9-1.1-0.9-1.6l0-0.1l0,0c2.3-2.8,3.6-5.7,3.8-8.6c0.3-3.8-1.4-6.5-2.2-7.5
				c-0.5,5.4-1.8,8.8-6.8,9.2L79,23l0.3-0.4c0.9-1.1,1.5-2.7,1.7-4.4c0.1-1.4-0.1-4-2.3-6.1l-0.1,0.1c-2.2,3.1-5.8,4.9-10.9,5.6
				c-2,0.3-4,0.3-6.1,0.3c-0.4-0.9-0.9-1.7-1.5-2.6C58.1,12.4,55.3,9.8,52,8c-4-2.1-8.8-3.2-14.3-3.2c-2.8,0-5.7,0.3-8.8,0.8
				c-4.7,1.1-8.8,2.9-12,5.5c-2.7,2.2-4.9,4.8-6.6,7.9C7.4,24.5,7,30,7.3,32.3c1.5-2.4,4.6-5.7,11.3-8.8l0.5-0.2L19,23.9
				c-0.2,1-3.1,4.2-6,6.4c6.1-0.9,9.1-4,10.1-5.3l0.8-1l-0.2,1.3c-0.2,1-0.2,2-0.1,2.9c0.4-0.3,0.8-0.7,1.2-1c4-3.4,7.2-6,12.1-6l1.2,0
				l-1.1,0.6c0,0-3.2,1.9-4.5,4.5l0.9-0.7l0,0c2.7-1.6,6-3.7,9.8-3.7c0.1,0,0.3,0,0.4,0c0,0,0,0,0.1,0l0.1,0l0.1,0.1
				c1.8,3,10,6.9,13.6,7.6c-1.9-1.2-3-2.5-3.6-3.5l-0.5-0.8l0.8,0.3c5.2,1.7,9.2,4.4,10.6,6.9c0.1-0.6,0.1-1.5,0.1-2.5
				c1.9,0.2,3.6,1.8,3.6,3.8c0,2-1.6,3.7-3.6,3.7c-0.1,0-0.2,0-0.2,0c-1.5,6.9-6.1,13-12,17c0,0,0.1,0,0.1-0.1c0.1,0.3,0.3,0.6,0.5,0.9
				c0,0,0,0,0,0c4,1.1,9.8,2.7,11.2,3v-0.1c1-1,7.3-2.3,13-2.3c1.9-0.5,3.5-1.3,4.8-2.2c2.3-1.6,3.6-3.8,3.8-6.4l0-0.2l0.2,0
				c4.1,0.3,7.8-5.7,8.4-13.3C90,34,87,32.8,85.6,31.6z"
    />
    <path
      className="superhero__hair--main"
      d="M85,30.2c7.9-9.8,1.2-16.8,1.2-16.8c-0.4,5.7-1.6,9.2-6.7,9.6c2.1-2.5,2.7-7.7-0.8-10.9l-0.2,0.3
				c-3.5,4.9-10.2,5.9-16.6,5.8c-4.2-8.3-13.5-16-33.2-12.6c-22,5-22.5,25-21.6,27.9c2.2-4.4,7.4-7.5,11.5-9.4c-0.2,1-3.7,4.8-6.9,6.9
				c5.9-0.5,9.6-3.1,11.5-5.6c-0.2,1.1-0.3,2.3-0.1,3.5c4.8-3.9,8.1-7.2,13.4-7.3c0,0-4.1,2.4-5,5.8l1.7-1.4c2.8-1.7,6.2-3.8,10.1-3.6
				c0,0,0,0,0,0c2.2,3.7,13.3,8.4,15.3,7.9c-2.4-1.2-3.9-2.7-4.7-4.1c5.7,1.9,10.1,5,10.8,7.6c0.2-0.6,0.3-1.8,0.3-3.4
				c1.8,0.2,3.3,1.7,3.3,3.6c0,2-1.6,3.7-3.6,3.7c-0.1,0-0.2,0-0.2,0c-1.5,7-6.1,13-12.1,17c0,0,0.1,0,0.1-0.1c0.1,0.3,0.3,0.6,0.5,0.9
				h0c4,1.1,9.8,2.7,11.2,3v-0.1c1.1-1.1,7.8-2.4,13.8-2.3c4.4-1.4,7.7-4.2,8-8.6c4.2,0.3,8.1-5.9,8.7-13.8
				C88.5,34.1,84.9,31.7,85,30.2z M86.8,14.7c1.4,4.5-0.9,8.7-5.8,8.6C85.3,22.6,86.4,19.5,86.8,14.7z M29.4,8.7
				C19.3,11,13.6,16.4,10.6,21.9c2.3-5.8,7.5-12.3,19-14.8C46.2,4.5,55,9.7,59.4,16.1C54.1,10.2,45,6.1,29.4,8.7z M62.6,19.5
				c-0.1-0.2-0.2-0.3-0.2-0.5c5.9,0.2,12.5-0.4,15.8-5.1l0.2-0.3c0,0,0,0,0,0C76.4,19.6,69.2,20.3,62.6,19.5z M75.5,55.9
				c3.5-1.4,6.9-3.7,9.1-6.1C83.1,53.4,79.7,55.2,75.5,55.9z"
    />
  </svg>
)
