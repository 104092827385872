import React from 'react'
import './Vine2.scss'

const Vine2 = () => (
  <svg role="presentation" className="welcome__image--vine-2" viewBox="0 0 86.5 364.8">
    <path
      className="vine2"
      d="M34.5,141.5c0.7,4.3,1.4,8.5,2.2,12.8c-5.8-6.8-14.2-12.1-24.6-9.1l-0.3,0.1c8.1,5.6,10.4,28.3,28.5,27.5
					c0.8,4,1.6,8.1,2.4,12.3c-4.2-6.2-11.1-12.2-20.2-9.7l-0.2,0.1c6.1,4.3,7.5,22.2,22.3,20.3c0.7,4.1,1.4,8.3,2,12.6
					c-3.7-3.2-9.3-4.5-14.7-3.1h-0.2c3.6,9.7,10.5,16.7,16.8,17.9c0.4,3.4,0.7,6.9,1,10.5c-5.2-6.3-14.7-9.6-24.1-7.7l-0.3,0.1
					c4.8,16.1,15.5,28.1,25.6,29.5c0.1,5,0.2,10.2,0.1,15.6c-3.4-7.1-12.8-10.8-21.7-8.5l-0.5,0.3c4.6,12.3,13.7,21.2,21.6,21.9
					c-0.2,5.6-0.5,11.5-1,17.6c-5.4-12.2-16.4-26.7-34.3-23.2l-0.4,0.1c9.5,8,8.6,38.1,33.5,37.2c-0.4,4.3-0.9,8.7-1.5,13.2
					c-2.4-5.6-9.4-9-16.5-7.8h-0.2c2.5,10.3,8.8,18.2,15.1,19.5c-0.7,4.6-1.4,9.4-2.2,14.2c-4-6.4-10.9-12.9-20.2-10.3l-0.2,0.1
					c5.8,4.1,7.2,20.7,20.6,20c7.6,2.2,16.5-6.3,20-16.6h-0.2c-5.6-1.3-11.5,0.7-14.5,4.6c0.7-3.9,1.3-7.8,1.8-11.6
					c6.7-0.8,14.4-8.3,18.5-18.7l-0.2-0.1c-6-1.6-12.4,0.1-16.3,3.9c0.4-3.4,0.8-6.8,1.1-10.1c10.1-0.9,21.9-12.1,28.1-27.7l-0.3-0.1
					c-10-2.7-20.6,0.6-26.1,7.6c0.2-3.3,0.4-6.5,0.5-9.7c8.4-1,17-11.3,20.4-24.9h-0.3c-8-1.4-15.8,1.7-19.7,7.2
					c0-5.2-0.1-10.1-0.2-14.9c22.7,0.1,21.4-27.5,29.9-35.1l-0.4-0.1c-15.5-2.7-25.2,8.8-30.3,19.6c-0.3-5.1-0.8-10.1-1.3-14.9
					c7.8-1,16-10.6,19.1-23.4h-0.2c-9.2-1.6-18.3,3.2-20.4,10.9c-0.7-4.9-1.4-9.7-2.2-14.3c7.6-1.5,15.2-10.8,18.2-23h-0.2
					c-9-1.5-17.9,3.2-20.1,10.7c-0.8-4.2-1.6-8.4-2.4-12.5c0.4,0,0.7-0.1,1-0.2l-0.1-0.4c20.8-2.5,14.9-27.6,21.4-35.5h-0.3
					c-15.1-0.7-22,11.8-24.6,22.5c-1.1-5.9-2.2-11.8-3.1-17.7c9.1-1.2,18.4-12.4,21.9-27.2h-0.3c-10.6-1.8-20.8,3.7-23.5,12.3
					c-0.5-5.2-0.8-10.4-1-15.7c6.6-1,14.1-8.4,18.1-18.5l-0.2-0.1c-6.9-1.9-14.3,0.5-17.9,5.6c0.1-3.5,0.3-6.9,0.6-10.5
					c6.8-0.1,15-7.6,19.2-18.3l-0.2-0.1c-6.8-1.9-14,0.5-17.5,5.5c0.4-3,0.9-6.1,1.5-9.3c21.4,1,20.1-22.7,27.8-29.2l-0.3-0.1
					c-11.2-1.6-18.9,4.7-23.8,12c0.9-3.5,1.8-7.2,2.9-10.9c11.9-7.3,14.1-27.2-1.4-33.4l-0.2-0.1c0.3,8.3-17.7,25.5-3.7,34.3
					c-1.3,4.8-2.5,9.5-3.6,14c-2.6-8.6-8.5-17.6-20.4-17h-0.3c5.5,6.6,0.8,27.5,18,29.8c-0.7,3.8-1.3,7.5-1.8,11.1
					c-3.2-6.9-9.2-14.3-19.1-12.6h-0.2c5.3,4.7,4.3,21.9,18.3,22.4c-0.3,3.8-0.6,7.6-0.7,11.2c-3.3-3-8.3-4.5-13.4-3.7h-0.2
					c2.1,9.6,7.7,17.1,13.5,19.2c0.1,4.4,0.4,8.7,0.8,12.9c-6-9.6-16-18.7-29.9-15.4l-0.3,0.1C11.2,116.3,12.1,143.4,34.5,141.5z"
    />
  </svg>
)

export default Vine2
