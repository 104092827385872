import React from 'react'
import './SunflowerPetals.scss'

const SunflowerPetals = ({className}) => (
  <svg role="presentation" viewBox="0 0 282 286.2" className={`${className} welcome__image--sunflower-petals`}>
    <path
      className="st0"
      d="M279.4,161.7c-2.3-4.1-6.2-7.7-11.3-10.4c-5.2-2.8-11.2-5.1-18-6.8c-9-2.3-18-3.5-26.8-3.7
				c-6.5-0.1-13.1,0.4-19.6,1.5c0-1.6,0-3.2-0.1-4.8c2.3,0.1,4.6,0.1,6.9,0.2c12.2,0.2,23.5-0.3,29.5-1.3c7-1.1,13.1-2.8,18.6-5.1
				c5.1-2.1,11.5-5.1,16.1-9.9c2.5-2.6,5.3-6.7,5-11.4c-0.3-3.7-2.4-7.2-5.8-9.7c-3.8-2.7-8.9-4.3-14.7-4.6c-0.7,0-1.5-0.1-2.2-0.1
				c-5.3-0.1-10.9,0.5-16.9,1.7c-15.8,3.1-29.7,9-42.4,17.7c-0.7-1.4-1.4-2.8-2.2-4.1c12.8-5.5,26-12.3,32-16.5
				c5.8-4,10.6-8.2,14.6-12.6c3.7-4.1,8.2-9.5,10.3-15.9c1.1-3.4,1.9-8.3-0.4-12.5c-1.8-3.2-5.3-5.5-9.4-6.2c-1.1-0.2-2.2-0.3-3.3-0.3
				c-3.8-0.1-7.8,0.8-11.9,2.5c-5.4,2.3-11,5.5-16.6,9.7c-12.9,9.7-23,20.9-30.7,34.4c-1.3-1-2.5-1.9-3.9-2.7
				c9.2-10.5,18.2-22.4,21.9-28.8c3.5-6.2,6.1-11.9,7.8-17.7c1.6-5.3,3.3-12.1,2.4-18.7c-0.5-3.5-1.8-8.3-5.8-11.1
				c-1.9-1.3-4.3-2.1-6.8-2.1c-1.4,0-2.9,0.2-4.4,0.6c-4.5,1.3-8.9,4.2-12.8,8.5c-3.9,4.4-7.6,9.7-10.8,15.9
				c-7.6,14.5-11.9,29.2-12.9,44.9c-1.6-0.3-3.2-0.5-4.8-0.7c3.9-13.7,7-28.7,7.6-36.2c0.5-7.1,0.3-13.4-0.6-19.3
				c-0.8-5.4-2.3-12.4-5.9-18c-1.9-3-5.2-6.7-10-7.5c-0.5-0.1-1.1-0.1-1.7-0.1c-3.2,0-6.4,1.2-9.1,3.6c-3.5,3.1-6.2,7.7-7.9,13.2
				c-1.7,5.6-2.6,12-2.9,19c-0.7,16.8,2,32.2,8.1,47.1c-1.9,0.5-3.8,1.1-5.6,1.9c-3.1-14.1-7.5-29.1-10.7-36.2
				c-2.9-6.5-6.1-11.9-9.7-16.7c-3.3-4.4-7.9-9.8-13.7-13c-2.9-1.6-5.8-2.4-8.4-2.5c-1.4,0-2.7,0.2-3.9,0.6c-3.5,1.2-6.4,4.2-7.8,8.1
				c-1.6,4.4-1.8,9.7-0.6,15.3c1.2,5.8,3.4,11.8,6.5,18.1c7.4,15.1,17.1,27.5,29.6,37.8c-1.3,1.1-2.5,2.4-3.7,3.6
				C90.6,88.9,80,77,74.1,71.9c-5.4-4.6-10.6-8.2-15.9-10.9c-4.9-2.5-11.3-5.5-18-5.9c-0.3,0-0.7,0-1,0c-3.2,0-7.7,0.5-10.9,3.6
				c-2.7,2.6-4,6.5-3.6,10.7c0.4,4.7,2.5,9.5,6,14.1c3.6,4.7,8.2,9.2,13.6,13.6c13.4,10.6,27.7,17.6,43.6,21.5c-0.8,2-1.5,4-2.1,6.1
				c-13.4-6.9-29.7-13.8-37.7-15.9c-6.9-1.8-13.1-2.8-19.1-3c-0.6,0-1.2,0-1.8,0c-4.5-0.1-11.1,0.1-17,2.5c-3.3,1.3-7.6,3.9-9.2,8.4
				c-1.3,3.5-0.8,7.6,1.4,11.2c2.4,4,6.4,7.5,11.5,10.2c5.2,2.7,11.3,4.8,18.1,6.4c8.1,1.9,16.3,2.9,24.2,3c9.1,0.1,18.2-0.9,27.2-3
				c0.1,2,0.3,4,0.6,6c-1.5,0-3-0.1-4.6-0.1c-14.5-0.2-29.7,0.6-37.2,2c-7,1.3-13,3.1-18.5,5.5c-5,2.2-11.4,5.4-15.9,10.3
				c-2.4,2.6-5.2,6.8-4.7,11.6c0.4,3.7,2.6,7.2,6.1,9.5c3.9,2.6,9,4,14.8,4.2c0.2,0,0.4,0,0.7,0c5.7,0.1,11.9-0.6,18.4-2.1
				c17.8-4,33.1-11.4,46.8-22.5c0.9,1.8,2,3.6,3.1,5.3c-14.1,6.2-30.2,14.7-37.2,19.8c-5.7,4.2-10.4,8.4-14.3,13
				c-3.6,4.2-8,9.7-9.9,16.1c-1,3.4-1.7,8.4,0.7,12.5c1.9,3.2,5.4,5.4,9.6,6c0.9,0.1,1.8,0.2,2.8,0.2c3.9,0.1,8.1-0.9,12.4-2.8
				c5.4-2.4,10.9-5.8,16.4-10.1c14.3-11.2,25-24.5,32.5-40.4c1.6,1.3,3.2,2.4,4.9,3.5c-9.9,11.5-20.8,26.2-24.9,33.7
				c-3.4,6.2-5.8,12.1-7.4,17.9c-1.5,5.3-3,12.2-2,18.8c0.5,3.5,2,8.3,6,10.9c1.9,1.2,4.1,1.9,6.5,1.9c1.6,0,3.1-0.2,4.7-0.7
				c4.5-1.4,8.8-4.4,12.6-8.8c3.8-4.5,7.3-9.9,10.4-16.2c8-16.1,11.9-32.4,12-49.8c1.9,0.5,3.8,0.9,5.8,1.1c-4,14.6-7.3,31.9-7.7,40.4
				c-0.3,7.1,0,13.4,1.1,19.3c1,5.4,2.6,12.3,6.3,17.8c2,2.9,5.4,6.6,10.1,7.3c0.5,0.1,0.9,0.1,1.4,0.1c3.3,0.1,6.6-1.3,9.3-3.8
				c3.4-3.2,6-7.8,7.5-13.4c1.5-5.7,2.3-12.1,2.4-19.1c0.3-17.6-3.1-33.7-10.2-49.1c1.8-0.4,3.7-0.8,5.5-1.3
				c2.7,14.6,7.1,31.1,10.2,38.7c2.7,6.5,5.8,12.1,9.3,17c3.2,4.5,7.6,10,13.4,13.4c3,1.7,6,2.7,8.7,2.7c1.3,0,2.5-0.2,3.6-0.5
				c3.5-1.1,6.5-4,8-7.9c1.7-4.3,2.1-9.6,1-15.3c-1.1-5.8-3.1-11.9-6-18.3c-7.1-15.5-16.8-28.4-29.3-39c1.4-1.1,2.8-2.2,4.1-3.3
				c8.7,11.8,19.1,24.1,25.1,29.4c5.3,4.7,10.4,8.4,15.7,11.3c4.8,2.6,11.2,5.7,17.8,6.3c0.5,0,1.1,0.1,1.6,0.1c3,0,7.3-0.5,10.4-3.4
				c2.7-2.5,4.1-6.4,3.8-10.6c-0.3-4.7-2.3-9.6-5.7-14.3c-3.5-4.8-7.9-9.4-13.3-13.9c-12.8-10.7-26.6-18-42-22.3c0.8-1.5,1.5-3,2.1-4.5
				c12.6,6.7,26.8,13,34.2,15.1c6.8,2,13,3.1,19,3.5c1.4,0.1,2.6,0.1,3.8,0.1c6.1,0.1,10.9-0.6,15-2.1c3.3-1.3,7.7-3.7,9.4-8.2
				C281.9,169.5,281.4,165.4,279.4,161.7z"
    />
    <path
      className="st1"
      d="M279.9,161.5c-2.6-4.6-6.9-8.2-11.5-10.7c-5.7-3.1-11.9-5.3-18.1-6.9c-15.5-3.9-30.9-4.8-46.6-2.2
				c0-1.2,0-2.4-0.1-3.6c14.2,0.5,29.2,0.1,36.5-1.1c6.4-1,12.8-2.6,18.8-5.1c5.8-2.4,11.9-5.4,16.3-10c3-3.2,5.5-7.3,5.2-11.9
				c-0.3-4.1-2.7-7.7-6.1-10.1c-4.3-3.1-9.7-4.4-15-4.7c-6.4-0.3-13,0.4-19.3,1.6c-15.5,3.1-29.6,8.9-42.6,17.8c-0.5-1-1.1-2.1-1.7-3.1
				c12.9-5.6,26-12.4,32.1-16.5c5.3-3.7,10.4-7.9,14.7-12.7c4.2-4.7,8.4-10,10.4-16.1c1.4-4.2,1.8-9-0.5-12.9c-2.1-3.6-5.8-5.8-9.8-6.5
				c-5.2-0.9-10.7,0.2-15.5,2.2c-5.9,2.5-11.6,5.9-16.7,9.8c-12.7,9.5-23,20.8-30.8,34.5c-0.9-0.7-1.9-1.4-2.9-2
				c9.3-10.7,18.3-22.5,21.9-28.9c3.2-5.6,6-11.6,7.8-17.8c1.8-6,3.3-12.7,2.5-19c-0.6-4.4-2.3-8.9-6-11.5c-3.4-2.4-7.7-2.7-11.7-1.6
				c-5.1,1.5-9.5,4.8-13,8.7c-4.3,4.8-7.9,10.3-10.9,16c-7.5,14.2-11.9,29.1-13,45.1c-1.2-0.2-2.4-0.4-3.6-0.5
				c3.9-13.8,7-28.7,7.6-36.2c0.5-6.5,0.4-13-0.6-19.4c-1-6.2-2.5-12.8-6-18.2c-2.4-3.7-5.9-7-10.4-7.8c-4.1-0.7-8.2,0.9-11.2,3.6
				c-4,3.5-6.5,8.4-8,13.5c-1.8,6.2-2.7,12.7-2.9,19.1c-0.6,16.5,1.9,32.1,8.1,47.3c-1.5,0.4-3,0.9-4.5,1.5C119.6,71,115.2,56,112.1,49
				c-2.7-5.9-5.8-11.7-9.7-16.8c-3.8-5-8.3-10.1-13.9-13.2c-3.8-2.1-8.5-3.4-12.8-1.9c-3.9,1.3-6.8,4.6-8.2,8.5
				c-1.8,5-1.8,10.5-0.7,15.7c1.3,6.3,3.7,12.5,6.5,18.2c7.3,14.8,17,27.4,29.7,37.9c-1,0.9-1.9,1.8-2.8,2.8
				c-9-11.6-19.7-23.5-25.6-28.5c-4.9-4.2-10.3-8-16-11c-5.6-2.9-11.8-5.6-18.2-5.9c-4.4-0.3-9.1,0.6-12.4,3.8c-3,2.9-4.1,7.1-3.7,11.2
				c0.5,5.3,2.9,10.3,6.1,14.4c3.9,5.1,8.7,9.7,13.7,13.7c13.1,10.4,27.5,17.6,43.8,21.6c-0.6,1.6-1.2,3.3-1.7,5
				c-13.7-7-29.8-13.8-37.7-15.9c-6.3-1.7-12.7-2.8-19.2-3c-6.3-0.2-13.1,0.1-19,2.5c-4.1,1.7-8,4.5-9.6,8.7c-1.4,3.9-0.7,8.2,1.4,11.7
				c2.7,4.6,7.1,8,11.8,10.4c5.7,2.9,12,5,18.3,6.4c17.2,3.9,34.3,4.1,51.6,0c0.1,1.6,0.3,3.2,0.5,4.8c-15.6-0.5-33.5,0.3-41.7,1.9
				c-6.4,1.2-12.7,2.9-18.6,5.6c-5.8,2.5-11.8,5.7-16.1,10.4c-3,3.2-5.3,7.5-4.9,12c0.4,4.1,2.9,7.7,6.3,9.9c4.4,3,9.8,4.2,15.1,4.3
				c6.4,0.2,13-0.7,19.3-2.1c17.4-3.9,33-11.2,46.9-22.5c0.8,1.5,1.6,2.9,2.5,4.2c-14.4,6.3-30.4,14.9-37.2,19.8
				c-5.2,3.8-10.2,8.1-14.4,13c-4.1,4.8-8.2,10.2-10,16.3c-1.3,4.2-1.6,9,0.8,12.9c2.1,3.6,6,5.7,10,6.2c5.3,0.8,10.7-0.5,15.5-2.6
				c5.9-2.6,11.4-6.2,16.5-10.2c14-11,24.9-24.3,32.6-40.5c1.3,1,2.6,2,4,2.9C100.6,203.5,90,218,86,225.4c-3.1,5.7-5.7,11.7-7.4,18
				c-1.7,6.1-3,12.7-2,19c0.7,4.3,2.5,8.8,6.3,11.3c3.5,2.3,7.8,2.5,11.7,1.3c5.1-1.6,9.4-5,12.8-9c4.2-4.9,7.6-10.5,10.5-16.3
				c7.8-15.8,11.9-32.2,12-49.9c1.5,0.4,3.1,0.7,4.6,0.9c-4,15-7.3,32.2-7.7,40.5c-0.3,6.5-0.1,13,1.1,19.4c1.1,6.2,2.8,12.8,6.4,18
				c2.5,3.6,6,6.9,10.5,7.5c4.1,0.6,8.1-1.1,11.1-3.9c3.9-3.6,6.3-8.6,7.7-13.7c1.7-6.2,2.4-12.8,2.5-19.2c0.3-17.2-3-33.5-10.2-49.2
				c1.5-0.3,2.9-0.6,4.3-1c2.8,15,7.1,31.3,10.2,38.7c2.5,6,5.5,11.8,9.3,17.1c3.7,5.1,8,10.3,13.5,13.5c3.8,2.2,8.4,3.6,12.8,2.2
				c4-1.3,6.9-4.5,8.4-8.3c2-4.9,2-10.5,1.1-15.6c-1.2-6.3-3.4-12.6-6-18.4c-7-15.3-16.6-28.3-29.4-39.2c1.1-0.8,2.2-1.7,3.2-2.6
				c8.8,12,19.3,24.3,25.2,29.5c4.8,4.3,10.1,8.3,15.8,11.4c5.5,3,11.7,5.8,18,6.4c4.4,0.4,9.2-0.4,12.5-3.5c3-2.8,4.3-7,4-11.1
				c-0.4-5.3-2.7-10.3-5.8-14.6c-3.8-5.2-8.4-9.9-13.4-14c-12.6-10.5-26.4-17.9-42.1-22.3c0.6-1.1,1.1-2.3,1.6-3.5
				c12.8,6.8,27,13,34.3,15.2c6.2,1.8,12.6,3.1,19.1,3.5c6.3,0.4,13.1,0.2,19-2c4.1-1.6,8.1-4.3,9.8-8.5
				C282.6,169.3,281.9,165,279.9,161.5z M238.5,102.8c5.6-1.3,11.5-2.1,17.2-2.1c4.7,0,9.4,0.7,13.3,2.9c2.9,1.6,5.1,4.3,5.3,7.4
				c0.1,1.8-0.4,3.5-1.3,5.1c-0.4-2.6-2.1-4.8-4.5-6.3c-3.6-2.4-8.3-3.5-12.9-3.7c-5.7-0.3-11.5,0.1-17.2,1
				c-13.8,2.2-26.6,6.3-38.6,12.9c-0.1-0.3-0.2-0.5-0.3-0.8C211.3,111.2,224.3,105.9,238.5,102.8z M212.5,64.4c4.7-3.4,9.7-6.4,15-8.7
				c4.3-1.9,9-3.1,13.3-2.6c3.4,0.3,6.3,1.9,7.8,4.7c0.8,1.6,1,3.4,0.8,5.2c-1.4-2.2-3.8-3.5-6.6-4c-4.2-0.8-9,0.1-13.3,1.7
				c-5.3,1.9-10.6,4.7-15.4,7.7c-12.1,7.6-22.4,16.7-31,27.7c-0.2-0.2-0.4-0.4-0.6-0.5C190.4,83.4,200.4,73.1,212.5,64.4z M171.7,41.5
				c2.7-5.1,5.9-10.1,9.6-14.4c3-3.6,6.7-6.7,10.8-8.2c3.2-1.2,6.5-1.1,9.1,0.8c1.4,1.1,2.4,2.6,3,4.3c-2.2-1.3-5-1.5-7.7-0.7
				c-4.2,1.2-8,4-11.2,7.3c-3.9,4.1-7.4,8.8-10.5,13.7c-7.6,12.2-12.8,25.1-15.7,38.9c-0.2-0.1-0.5-0.1-0.7-0.2
				C160.1,68.5,164.6,54.8,171.7,41.5z M124.9,38.9c0.2-5.8,0.9-11.6,2.3-17.2c1.1-4.5,3.1-9,6.1-12.1c2.3-2.4,5.4-3.8,8.5-3.2
				c1.7,0.3,3.3,1.3,4.6,2.6c-2.6-0.2-5.2,0.9-7.2,2.7c-3.2,2.9-5.4,7.1-6.8,11.5c-1.7,5.4-2.8,11.2-3.3,16.9
				c-1.4,14.5-0.5,28.4,3.1,42.2c-0.2,0-0.5,0.1-0.7,0.1C126.4,68.5,124.4,54.1,124.9,38.9z M79.1,59.2c-2.8-5-5.3-10.4-7.1-15.8
				c-1.4-4.5-2.1-9.2-1.1-13.5c0.7-3.3,2.6-6.1,5.6-7.2c1.6-0.6,3.4-0.6,5.2-0.2c-2.3,1.2-3.9,3.4-4.7,6.1c-1.2,4.1-0.9,8.9,0.2,13.4
				c1.4,5.5,3.5,11,6,16.2c6.4,13.2,14.6,24.7,25,34.6c-0.2,0.1-0.4,0.3-0.6,0.4C96,83.8,86.7,72.5,79.1,59.2z M52.5,92.5
				c-4.7-3.3-9.3-7-13.2-11.2c-3.2-3.4-5.9-7.4-6.9-11.7c-0.8-3.3-0.3-6.6,1.9-8.9c1.2-1.3,2.8-2,4.6-2.5c-1.6,2-2.1,4.8-1.6,7.6
				c0.7,4.3,3,8.4,6,12c3.6,4.4,7.9,8.4,12.4,12c11.2,8.9,23.3,15.6,36.7,20.1c-0.1,0.2-0.3,0.5-0.4,0.7C77.8,107.1,64.8,101,52.5,92.5
				z M38.1,138.5c-5.7-1-11.4-2.4-16.7-4.5c-4.3-1.7-8.5-4.2-11.2-7.6c-2.1-2.6-3.1-5.9-2.1-8.9c0.6-1.7,1.7-3.1,3.1-4.2
				c-0.6,2.5,0.2,5.2,1.8,7.5c2.4,3.6,6.3,6.3,10.5,8.3c5.1,2.4,10.8,4.2,16.4,5.5c14.6,3.4,29.1,4.2,43.8,2.2c0,0.2,0,0.4,0,0.6
				C68.6,140.9,53.6,141.1,38.1,138.5z M46.9,182.5c-5.5,1.7-11.3,3-17,3.6c-4.6,0.4-9.5,0.1-13.4-1.7c-3.1-1.4-5.4-3.8-5.9-6.9
				c-0.3-1.7,0.1-3.5,0.9-5.2c0.6,2.5,2.5,4.6,5,5.9c3.8,2.1,8.5,2.8,13.2,2.6c5.7-0.2,11.5-1.1,17.1-2.5c14.6-3.5,27.8-9.3,39.9-17.7
				c0.1,0.2,0.1,0.4,0.2,0.6C75,170.9,61.8,177.9,46.9,182.5z M74.6,219.1c-4.1,4-8.7,7.8-13.6,10.8c-4,2.5-8.4,4.3-12.8,4.5
				c-3.4,0.1-6.6-1-8.4-3.6c-1-1.4-1.5-3.2-1.5-5c1.7,2,4.3,3,7.1,3.1c4.3,0.2,8.9-1.3,12.9-3.5c5-2.7,9.8-6.1,14.2-9.9
				c11.2-9.5,20.2-20.3,27.3-32.9c0.2,0.2,0.3,0.4,0.5,0.5C94.1,196.7,85.6,208.6,74.6,219.1z M115.6,242.2c-2.1,5.4-4.7,10.7-7.9,15.4
				c-2.6,3.9-5.9,7.4-9.8,9.4c-3,1.5-6.4,1.8-9.1,0.2c-1.5-0.9-2.6-2.3-3.5-3.9c2.4,1.1,5.1,0.9,7.7-0.2c4-1.6,7.5-4.9,10.3-8.6
				c3.4-4.5,6.4-9.6,8.8-14.9c6.3-13.4,10.1-27.1,11.2-41.6c0.2,0.1,0.4,0.2,0.7,0.2C124.2,213.4,121.2,227.9,115.6,242.2z
				 M159.2,244.7c0.4,5.8,0.4,11.7-0.4,17.3c-0.6,4.6-2.1,9.2-4.8,12.7c-2.1,2.7-5,4.4-8.1,4.1c-1.8-0.2-3.4-0.9-4.8-2.1
				c2.6,0,5-1.4,6.9-3.5c2.9-3.2,4.6-7.7,5.6-12.2c1.2-5.6,1.6-11.4,1.5-17.2c-0.1-14.8-2.6-28.9-7.9-42.5c0.2,0,0.5,0,0.7,0
				C154.5,215,158.1,229.3,159.2,244.7z M201.1,227.4c2.9,5,5.5,10.3,7.2,15.8c1.5,4.4,2.2,9.2,1.3,13.5c-0.7,3.3-2.5,6.1-5.5,7.3
				c-1.6,0.6-3.4,0.7-5.2,0.3c2.3-1.2,3.9-3.5,4.7-6.2c1.2-4.2,0.8-8.9-0.4-13.4c-1.4-5.5-3.6-11-6.2-16.1c-6.5-13-14.7-24.3-25.2-34.1
				c0.2-0.1,0.5-0.2,0.7-0.4C184.2,203.3,193.5,214.3,201.1,227.4z M233.3,192.8c4.6,3.4,9.1,7.3,12.9,11.6c3.1,3.5,5.6,7.6,6.5,11.9
				c0.7,3.3,0.1,6.6-2.2,8.8c-1.3,1.2-2.9,2-4.7,2.3c1.7-2,2.2-4.7,1.9-7.5c-0.5-4.3-2.8-8.5-5.6-12.2c-3.5-4.5-7.6-8.7-12-12.4
				c-10.9-9.3-22.8-16.4-35.9-21.3c0.1-0.2,0.3-0.5,0.4-0.7C208.5,177.4,221.3,183.8,233.3,192.8z M275.2,171.1c-0.7,1.6-1.9,3-3.4,4
				c0.7-2.5,0.1-5.2-1.3-7.6c-2.2-3.7-6-6.7-10-8.9c-5-2.7-10.5-4.8-16-6.5c-13.6-4-27.1-5.7-41-4.9c0-0.3,0-0.6,0.1-0.8
				c14.3-2,28.5-1.2,42.9,2.1c5.6,1.3,11.3,3,16.5,5.4c4.2,2,8.2,4.7,10.8,8.3C275.6,164.9,276.4,168.2,275.2,171.1z"
    />
  </svg>
)

export default SunflowerPetals
